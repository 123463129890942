<!-- <div>
  <button mat-button class="mobile_margin pt-2 pl-2" mat-raised-button color="primary" [disabled]='!add'
    (click)="create()" [ngStyle]="{'float': 'right','margin-top': '40px'}">Add</button>
</div> -->
<app-dynamic-form-molecule *ngIf="formData && fm" [formData]='formData'
  (submitForm)="submitForm($event)"></app-dynamic-form-molecule>
<div class='row table-header'>
  <button mat-button mat-raised-button color="primary" *ngIf="fm" (click)="close()">
    Close
  </button>
</div>

<div [ngStyle]="{'margin-top': '80px'}">
  <ng-container>
    <mat-tab-group mat-stretch-tabs="false" >
      <mat-tab  class="mat-tab-label bold-tab" label="Supplier Dispatch Plan">
          <button mat-button class="mobile_margin" mat-raised-button color="primary" [disabled]='!add'
            (click)="create()" [ngStyle]="{'float': 'right','margin-top': '40px','padding':'right'}">Add</button>
        <div ng-controller="customersCtrl">
          <table mat-table class="full-width-table" [dataSource]="stocksource" matSort>
            <ng-container *ngFor="let disCol of displayStockColumns" matColumnDef="{{disCol}}">
              <th mat-header-cell *matHeaderCellDef class="capitalize"> {{addSpaces(disCol)}} </th>
              <td mat-cell *matCellDef="let element" class='table_cell'> {{element[disCol]}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayStockColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayStockColumns;"></tr>
          </table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</div>
