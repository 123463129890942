import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthGuard } from './auth-guard.service';
import { EmitterService } from './emitter.service';

import { MaterialsService } from './materials.service';
import { CommonUtilityService } from './common-utility-service';
import { FormControlService } from './form-control.service';
import { FormDataConstructorService } from './form-data-constructor.service';
import { ParameterMgtService } from './parameter-mgt.service';
import { LocationsService } from './locations-service';
import { ReportsService } from './reports.service';
import { LoaderService } from './loader.service';
import { SearchFilterService } from './search-filter.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule
  ],
  providers: [MaterialsService, CommonUtilityService, AuthGuard, EmitterService, FormDataConstructorService, FormControlService, ParameterMgtService, LocationsService, ReportsService, LoaderService, SearchFilterService]
})
export class ServicesModule { }
