import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd  } from '@angular/router';
import { ReportsService } from '../../../../services/reports.service';

@Component({
  selector: 'app-reports-organism',
  templateUrl: './reports-organism.component.html',
  styleUrls: ['./reports-organism.component.scss']
})
export class ReportsOrganismComponent implements OnInit {
  // data: any;
  locationFiltersData: any;
  chartsId: any[];
  filters: any;
  reportsData: any;
  iFrameData : any[];
  reportName: String = '';
  reportNameWithSpace: String = '';
  finalFilteredData: any[] = [];
  dataSource: String = "";
  @Input() reportNameAlias: any;
  hideFilters: boolean = false;
  constructor(private router: Router, private route: ActivatedRoute, private reportsService: ReportsService) {
    this.route.queryParams.subscribe(params => {
      if ( params && params.reportName ) {
        this.reportName = params.reportName;
        this.reportNameWithSpace = params.reportName.replace(/_/g, " ");
        this.reportsService.getReportDetails(params.reportName).subscribe(data=> {
          this.chartsId = data && data['chartsid'] && data['chartsid'].length > 0 && data['chartsid'];
          this.dataSource = data && data['dataSource'];
          let locationFilterapi = data && data['_links'] && data['_links'].LocationFilter && data['_links'].LocationFilter.href;
          this.locationFiltersData = '';
          // this.data = '';
          this.iFrameData = [];
          this.filters = '';
          this.reportsData = '';
          if(locationFilterapi) {
            this.reportsService.getLocationFilter(locationFilterapi).subscribe(filterDetails => {
              this.locationFiltersData = filterDetails;
            })
          }
        })
      }
    });
  }
  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if(this.reportNameAlias) {
      this.reportName = this.reportNameAlias;
      this.reportNameWithSpace = this.reportNameAlias.replace(/_/g, " ");
      this.reportsService.getReportDetails(this.reportNameAlias).subscribe(data=> {
        this.chartsId = data && data['chartsid'] && data['chartsid'].length > 0 && data['chartsid'];
        this.dataSource = data && data['dataSource'];
        let locationFilterapi = data && data['_links'] && data['_links'].LocationFilter && data['_links'].LocationFilter.href;
        this.locationFiltersData = '';
        // this.data = '';
        this.iFrameData = [];
        this.filters = '';
        this.reportsData = '';
        if(locationFilterapi) {
          this.reportsService.getLocationFilter(locationFilterapi).subscribe(filterDetails => {
            this.locationFiltersData = filterDetails;
          })
        }
      })
    }
  }

  getReportsByFilters(filterData) {
    this.iFrameData = [];
    this.finalFilteredData = filterData;
    this.chartsId.map(chartId => {
      this.reportsService.getURLFromChartId(chartId, filterData).subscribe(data => {
        this.reportsData = data;
        this.iFrameData.push(`<iframe src="${data.url}" width="100%" height="650" style="border:0" frameborder="0" allowfullscreen></iframe`);
        // this.data = `<iframe src="${data.url}" width="100%" height="650" style="border:0" frameborder="0" allowfullscreen></iframe`;
        this.filters = data.filters;
        if(this.reportNameAlias) {
          this.hideFilters = true;
        }
      })
    })
  }

  downLoadReport(){
    let chartId = this.chartsId[0];
    this.reportsService.downLoadReport(chartId,this.dataSource, this.reportName);
  }

}
