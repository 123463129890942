import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MaterialsTemplateComponent } from './materials-template/materials-template.component';
import { LocationsTemplateComponent } from './locations-template/locations-template.component';
import { MyNavTemplateComponent } from './my-nav-template/my-nav-template.component';
import { HomePageTemplateComponent } from './home-page-template/home-page-template.component';
import { LayoutModule } from '@angular/cdk/layout';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../material.module';
import { OrganismsModule } from '../shared/organisms/organisms.module';
import { PipesModule } from '../shared/pipes/pipes.module';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

const components = [
  HomePageTemplateComponent,
  MaterialsTemplateComponent,
  MyNavTemplateComponent,
  LocationsTemplateComponent
];
@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    LayoutModule,
    RouterModule,
    AppRoutingModule,
    MaterialModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    OrganismsModule,
    PipesModule
  ],
  exports: components
})
export class TemplatesModule { }
