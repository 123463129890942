<!-- eq, regex, in -->
<div class="reports-filter-wrapper">
    <div class="container reports-filter-container">
        <div class="row reports-filter-row">
            <div class="col-md-3 col-xs-12">
                <mat-form-field appearance="fill">
                    <mat-label>{{locationFilters?.label}}</mat-label>
                    <mat-select [formControl]="locationFilterValues" multiple>
                        <mat-option #allLocationSelected (click)="toggleLocationAllSelection()" [value]="0">All</mat-option>
                        <mat-option *ngFor="let lctnFilter of locationFilters?.labelValues" [value]="lctnFilter.key" (click)="locationOneSelected(allLocationSelected.viewValue)">
                            {{lctnFilter?.key}} - {{lctnFilter?.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- <app-dynamic-filter-dropdowns-molecule [filters]="reportsData?.colorFilters"></app-dynamic-filter-dropdowns-molecule> -->
            <ng-template [ngIf]="reportsData && reportName === 'Buffer_Penetration_Report'">
            <div class="col-md-3 col-xs-12"  *ngFor="let colorFilter of colorFiltersLocal; let index=index">
                <mat-form-field appearance="fill" *ngIf="index == 0">
                    <mat-label>{{colorFilter?.label}}</mat-label>
                    <mat-select [(ngModel)]="selectedTechBufferKey" (selectionChange)="onColorChange($event.value, colorFilter, 'techBuffer')">
                        <mat-option *ngFor="let color of colorFilter?.values" [value]="color">
                            {{color}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" *ngIf="index == 1">
                    <mat-label>{{colorFilter?.label}}</mat-label>
                    <mat-select [(ngModel)]="selectedEcoBufferKey" (selectionChange)="onColorChange($event.value, colorFilter, 'ecoBuffer')">
                        <mat-option *ngFor="let color of colorFilter?.values" [value]="color">
                            {{color}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-template>
            <!-- <div class="col-md-3 col-xs-12" *ngIf="filters && colorFilters?.values">
                <mat-form-field appearance="fill">
                    <mat-label>{{colorFilters?.label}}</mat-label>
                    <mat-select [formControl]="colorFilterValue">
                        <mat-option *ngFor="let color of colorFilters?.values" [value]="color">
                            {{color}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->
            <div class="col-md-4 col-xs-12 d-flex other-filters-wrapper pl-2 pr-2 mr-2 ml-2" *ngIf="filters && allOtherFilters?.length > 0">
                <div class="col-md-4 col-xs-12 p-0">
                    <mat-form-field>
                        <mat-label>Choose Filter</mat-label>
                        <mat-select [(ngModel)]="selectedOtherFilterKey" (selectionChange)="onOtherKeyChanges()">
                            <mat-option *ngFor="let otherFilter of allOtherFilters" [value]="otherFilter.key">
                                {{otherFilter.label ? otherFilter.label : otherFilter.key}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-xs-12 p-0 pl-2">
                    <mat-form-field>
                        <mat-label>Choose Sign</mat-label>
                        <mat-select [(ngModel)]="selectedOtherFilterOperator">
                            <mat-option *ngFor="let otherFilterOperator of allOtherFiltersOperators" [value]="otherFilterOperator.key">
                                {{otherFilterOperator.label ? otherFilterOperator.label : otherFilterOperator.key}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-xs-12 p-0 pl-2">
                    <mat-form-field>
                        <input matInput autocomplete="off" placeholder="Enter Value"
                            [(ngModel)]="selectedOtherFilterValue">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 col-xs-12 add-filter-button-wrapper">
                <button mat-flat-button color="primary" (click)="addFilter()" class="add-filter-button">Add
                    Filter</button>
            </div>
        </div>
    </div>
</div>
<div class="filters-list-wrapper" *ngIf="selectedFilters?.length > 0">
    <div class="">
        <div class="filter-value-wrapper">
            <div *ngFor="let filter of selectedFilters;let i = index" class="single-filter-value m-2 d-inline-block">
                <span>
                    {{filter.label ? filter.label : filter.key}}
                </span> :
                <b *ngFor="let value of filter.values; let j = index">
                    <span *ngIf="j !== 0">,</span>{{value}} 
                    <span *ngIf="filter.key == 'locationCode'">
                        {{getLocationName(value)}}
                    </span>
                </b>
                <b *ngIf="filter?.value">
                    <span>{{filter.value}}</span>
                </b>
                <span class="material-icons" (click)="closeFilter(i)">
                    close</span>
            </div>
        </div>
    </div>
</div>

<div *ngIf="reportsData && reportsData?.reportDate">
    <div class="d-flex justify-content-end">
        Reports are generated as on '<b>{{ reportsData?.reportDate  | date:'longDate'}}</b>'
    </div>
</div>
