import { FormBase } from '../interfaces/formInterface';

export class DropdownField extends FormBase<string> {
  controlType = 'dropdown';
  options = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['values'] || [];
  }
}