import { Component, OnInit } from '@angular/core';
import { CommonUtilityService } from '../../../services/common-utility-service';
import { ActivatedRoute } from '@angular/router';
import { BulkUploadServiceService } from '../../../services/bulk-upload-service.service';


@Component({
  selector: 'app-bulk-upload-molecule',
  templateUrl: './bulk-upload-molecule.component.html',
  styleUrls: ['./bulk-upload-molecule.component.scss']
})
export class BulkUploadMoleculeComponent implements OnInit {
  fileUrl;
  uploadName = 'upload file';
  page: any;
  constructor(private uploadService: BulkUploadServiceService,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.page = params.page;   
    });
  }

  ngOnInit(): void {
    //this.downloadFile();
  }
  downloadTemplate() {
    this.route.queryParams.subscribe(params => {
      this.uploadService.downloadTemplate(params.page);
    });
  }
  downloadFNTemplate() {
    this.route.queryParams.subscribe(params => {
      this.uploadService.downloadFnTemplate(params.page);
    });
  }

  xlsxInputChange(event: any) {
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      this.uploadName = event.target.files[0].name;
      this.uploadService.uploadFile(event.target.files[0], this.page).subscribe((res) => {
        alert("file uploaded successfully");
        this.uploadName = 'upload File';
      }, (error) => {
        alert("upload failed. Please try again");
        this.uploadName = 'upload File';
      })
    }
  }

}
