import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shareofbusiness-organism',
  templateUrl: './shareofbusiness-organism.component.html',
  styleUrls: ['./shareofbusiness-organism.component.scss']
})
export class ShareOfBusinessOrganismComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
