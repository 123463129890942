import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stock-criteria',
  templateUrl: './stock-criteria.component.html',
  styleUrls: ['./stock-criteria.component.scss']
})
export class StockCriteriaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
