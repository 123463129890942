<p class="col-12">All Fields marked with * are mandatory</p>
<mat-card class='form-wrapper'>
    <form [formGroup]="form" *ngIf="formData">
        <div *ngFor="let data of formData" class="form-row">
            <app-dynamic-form-field-molecule [fieldData]="data" [form]="form"></app-dynamic-form-field-molecule>
        </div>

        <div class="form-row">
            <button mat-raised-button color='primary' type="submit" (click)="save()" [disabled]="!form.valid">Save</button>
        </div>
    </form>
</mat-card>