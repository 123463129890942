import { Injectable } from '@angular/core';
import { FormBase } from '../interfaces/formInterface';
import { DropdownField } from '../form-fields/form-dropdown';
import { TextboxField } from '../form-fields/form-textbox';
import { RadioField } from '../form-fields/form-radio';
import { DatePickerField } from '../form-fields/form-datepicker';
import { EncryptedTextField } from '../form-fields/form-encrypt';

@Injectable({
  providedIn: 'root'
})
export class FormDataConstructorService {

  constructor() { }

  formConstructor(data) {
    const structuredForm = [];
    data.map((each) => {
        switch (each.dataType) {
          case 'Encrypt':
          structuredForm.push(new EncryptedTextField(each));
          break;
          case 'Boolean':
          structuredForm.push(new RadioField(each));
          break;
          case 'Enum':
          structuredForm.push(new DropdownField(each));
          break;
          case 'TimeStamp':
          case 'Date':
          structuredForm.push(new DatePickerField(each));
          break;
          case 'String':
          default:
          structuredForm.push(new TextboxField(each));
        }
    });
    const formData: FormBase<any>[] = structuredForm;
    return formData;
  }
}
