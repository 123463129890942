<div [formGroup]="form" *ngIf='fieldData'>


  <div [ngSwitch]="fieldData.controlType">

    <mat-form-field *ngSwitchCase="'textbox'" appearance="outline">
      <mat-label>{{fieldData.label}}</mat-label>
      <input matInput placeholder="Input" [readonly]="fieldData.isReadOnly" [id]="fieldData.identifier"
        [formControlName]="fieldData.identifier">
      <mat-error class="error-message" *ngIf="!isValid">{{fieldData.label}} is required</mat-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'passwd'" appearance="outline">
      <mat-label>{{fieldData.label}}</mat-label>
      <input matInput placeholder="Input" [readonly]="fieldData.isReadOnly" [id]="fieldData.identifier"
        [formControlName]="fieldData.identifier" type="password">
      <mat-error class="error-message" *ngIf="!isValid">{{fieldData.label}} is required</mat-error>
    </mat-form-field>


     <mat-form-field *ngSwitchCase="'dropdown'" appearance="outline">
      <mat-label>{{fieldData.label}}</mat-label>
      <mat-select [formControlName]="fieldData.identifier">
        <mat-option *ngFor="let opt of fieldData.options" [value]="opt">
          {{opt}}
        </mat-option>
      </mat-select>
      <mat-error class="error-message" *ngIf="!isValid">{{fieldData.label}} is required</mat-error>
    </mat-form-field>

  <div class="radio-wrapper" *ngSwitchCase="'radio'">
    <label  class="radio-label">{{fieldData.label}}</label>
    <mat-radio-group [formControlName]="fieldData.identifier" class="radio-group">
      <mat-radio-button class="radio-button" *ngFor="let opt of fieldData.options" [checked]="fieldData.value !== null && fieldData.value !== undefined && fieldData.value.toString() == opt.key ? true : false" [value]="opt.key">
        {{opt.value}}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <mat-form-field *ngSwitchCase="'datepicker'" appearance="fill" >
    <mat-label>{{fieldData.label}}</mat-label>
    <input matInput  [matDatepicker]="picker1" [min]="currentDate" [disabled]="fieldData.isReadOnly" [id]="fieldData.identifier"
    [formControlName]="fieldData.identifier">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
    <mat-error class="error-message" *ngIf="!isValid">{{fieldData.label}} is required</mat-error>
  </mat-form-field>
  </div>

</div>
