import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accessRules'
})
export class AccessRulesPipe implements PipeTransform {

  transform(value: any, header: any): unknown {
    if (!value) {
      return value;
    }
    if (header === 'Parameters Management') {
      value = value.filter(item => item.rules && item.rules.read === true);
    } else {
      value = value.filter(item => item.rules && item.rules.read === true);
    }
    return value;
  }

}
