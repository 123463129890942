import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { userColumns } from '../../../../properties/tableConfig';
import { UserService } from '../../../../services/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { CommonUtilityService } from '../../../../services/common-utility-service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-table-molecule',
  templateUrl: './user-table-molecule.component.html',
  styleUrls: ['./user-table-molecule.component.scss']
})
export class UserTableMoleculeComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ELEMENT_DATA: any[];
  dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  displayedColumns = userColumns;
  add = false;
  update = false;
  nextLink: string;
  nextUsersList$ = new Subscription();
  page: any;
  searchField;
  clearSearchField() {
    this.searchField = '';
    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }
  constructor(private service: UserService,
    private utilService: CommonUtilityService,
    private searchService: SearchFilterService,) {
  }

  ngOnInit() {

    this.service.getAllUsers().subscribe((res: any) => {
      this.ELEMENT_DATA = res.users;
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      if (res) {
        this.nextLink = res?._links?.next?.href;


        this.page = res?._links?.next?.href.split("?")[1];

      }
    });
    const accessRules = JSON.parse(this.utilService.getCookie('accessRules')).accessRules;
    accessRules.map(each => {
      if (each.entity === 'user') {
        this.add = each.create;
        this.update = each.update;
      }
    });
  }
  public doFilter = (value: string) => {
    // this.dataSource.filter = value.trim().toLocaleLowerCase();
    if(!value)   {
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    else this.searchService.searchFilter('user', value, 'userId').subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<any>(res.users);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

    })

  }

  getNextUsers() {
    if (this.page) {
      this.nextUsersList$ = this.service.getNextUsers(this.page).subscribe((res: any) => {
        //this.nextLink = res?._links?.next?.href;
        this.page = res?._links?.next?.href.split("?")[1];
        this.ELEMENT_DATA.push(...res.users);
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.nextPage();
      });
    }
  }
  pageChanged(event) {
    if (this.page) {
      if (!(event.previousPageIndex > event.pageIndex)) {
        this.getNextUsers();
      }
    }
  }
  ngOnDestroy() {
    this.nextUsersList$.unsubscribe();
  }
}

