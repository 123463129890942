import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EmitterService } from 'src/app/services/emitter.service';
import { SsoCallbackService } from '../../../services/sso-callback.service'
@Component({
  selector: 'app-sso-callback',
  templateUrl: './sso-callback.component.html',
  styleUrls: ['./sso-callback.component.scss']
})
export class SsoCallbackComponent implements OnInit {
  res:any;
  color:any="red";
  flag:boolean=false;
  constructor(private router:ActivatedRoute,
    private route: Router,
    private SsoCallbackService:SsoCallbackService, private emitter: EmitterService) { }

    goToMainPage() {
      this.route.navigate(['/']).then(() => {
        location.reload();
      });
    }
  ngOnInit() {
    const path = this.route.routerState.snapshot.url;
    this.router.queryParams.subscribe(params => {
    });

   this.SsoCallbackService.callBack(path).subscribe(
    (res)=>{
      this.color="green"
      this.res="Authorisation is done successfully";
   },
   (error) => {
    if(error.status===200){
      this.res="Authorisation is done successfully";
      this.flag=true;
      this.color="green"
    }else{
      this.res=error.error;
      this.flag=true;
      this.color="red"

    }
    // this.emitter.refreshSideMenu.next(true)
  }
  );


  }

}
