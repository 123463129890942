import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-materials-template',
  templateUrl: './materials-template.component.html',
  styleUrls: ['./materials-template.component.scss']
})
export class MaterialsTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
