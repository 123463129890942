import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EmitterService {

    // private userContextData = new BehaviorSubject<UserContext>(
    //     new UserContext('', '', false));
    //   userContext = this.userContextData.asObservable();

    //   changeUserContext(data: UserContext) {
    //     this.userContextData.next(data);
    //   }
    public isLoggedInCheck = new Subject();
    public refreshSideMenu = new Subject();
}

export class UserContext {
    constructor(public displayUID: string, public displayName: string, public isLoggedIn: boolean) {
    //   this.displayUID = displayUID;
    //   this.displayName = displayName;
      this.isLoggedIn = isLoggedIn;
    }
  }
