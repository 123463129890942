import { Component, OnInit } from '@angular/core';
import { ShareOfBusinessService } from '../../../../services/shareofbusiness.service';
import { FormDataConstructorService } from '../../../../services/form-data-constructor.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-shareofbusiness-form-organism',
  templateUrl: './shareofbusiness-form-organism.component.html',
  styleUrls: ['./shareofbusiness-form-organism.component.scss']
})
export class ShareOfBusinessFormOrganismComponent implements OnInit {
  formData: any;
  addFlag: any = true;
  shareOfBusinessId: string;
  constructor(private shareOfBusinessService: ShareOfBusinessService, private snack: MatSnackBar,
              private formService: FormDataConstructorService, private route: ActivatedRoute,
              private router:Router) { 
                this.shareOfBusinessService.getShareOfBusinessForm().subscribe((res: any) => {
                  let metaData  = res.shareOfBusinessMetadataList;
                  this.route.queryParams.subscribe(params => {
                    if ( params && params.sobid) {
                      const id = params.sobid;
                      this.addFlag = false;
                      let processedMetaData = [];
                      this.shareOfBusinessService.getShareofBusinessById(id).subscribe((data: any) => {
                        if (data) {
                          this.shareOfBusinessId = data.id;
                          this.shareOfBusinessService.getShareOfBusinessAttributes(this.shareOfBusinessId).subscribe((attributeData: any) => {
                            data.dynamicAttrs = attributeData.shareOfBusinessAttributes;
                            const processedAttrs = this.processAttrData(data);
                            processedMetaData = this.processMetaData(metaData, processedAttrs);
                            this.formData = this.formService.formConstructor(processedMetaData);
                          });
                        }
                      });
                    } else {
                      this.addFlag = true;
                      this.shareOfBusinessId = null;
                      metaData = metaData.filter(each => each.identifier !== 'createdTime' && each.identifier !== 'modifiedTime');
                      this.formData = this.formService.formConstructor(metaData);
                    }
                  });
                });
              }

  ngOnInit(): void {
  }
  processAttrData(data) {
    data.dynamicAttrs && data.dynamicAttrs.map((each) => {
      const key = each.attributeName;
      const val = each.attributeValue;
      data[key] = val;
    });
    // delete data.dynamicAttrs;
    return data;
  }

  processMetaData(metaData, data) {
    const processedMetaData = [];
    Object.keys(data).map((singleKey) => {
      metaData.map((eachMetaData) => {
        if (eachMetaData && singleKey === eachMetaData.identifier) {
          if(eachMetaData.dynamic) {
            data && data.dynamicAttrs && data.dynamicAttrs.map((dynamicAttr) => {
              if(singleKey === dynamicAttr.attributeName) {
                eachMetaData['id'] = dynamicAttr.id;
              }
            })
          }
          eachMetaData.value = data[singleKey];
          if ((singleKey  === 'createdTime' || singleKey === 'modifiedTime')) {
            eachMetaData.isReadOnly = true;
            processedMetaData.push(eachMetaData);
          } else {
            eachMetaData.isReadOnly = false;
            processedMetaData.push(eachMetaData);
          }
        }
      });
    });
    return processedMetaData;
  }

  submitForm(formValues) {
    let resultJson = {};
    let resultJsonAttributes = [];
    resultJson['id'] = this.shareOfBusinessId;
    this.formData.map((eachFormData) => {
      if(eachFormData.dynamic) {
        const attributeId = this.addFlag ? null : eachFormData.id;
        resultJsonAttributes.push({
          'id': attributeId,
          'attributeName': eachFormData.identifier,
          'attributeValue': formValues[eachFormData.identifier].value
        });
      } else {
        resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value; 
        if(eachFormData.dataType == "Array") {
          resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value && formValues[eachFormData.identifier].value instanceof Array ? formValues[eachFormData.identifier].value : formValues[eachFormData.identifier].value.split(','); 
        }
      }
    });
    this.shareOfBusinessService.saveShareOfBusiness(resultJson).subscribe((response)=> {
      resultJsonAttributes = resultJsonAttributes.map((obj) => {
        obj['sobid'] = response['id'];
        return obj;
      });
      this.shareOfBusinessService.saveShareOfBusinessAttributes(resultJsonAttributes).subscribe((response1)=> {
      });
      const message = this.addFlag ? 'Added successfully' : 'changes saved successfully';
      this.snack.open(message, 'close', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.router.navigate(['/shareofbusiness']);
    });
}

}
