import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PlanningStockService } from 'src/app/services/planning-stock.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-bpr-status-history',
  templateUrl: './bpr-status-history.component.html',
  styleUrls: ['./bpr-status-history.component.scss']
})
export class BprStatusHistoryComponent implements OnInit, OnDestroy {
  @Input() materialCode: any;
  @Input() locationCode: any;
  materialLocationStatusHistoryDataList: any;
  sortWithColor: any;
  statusList$ = new Subscription();
  getTextColor(fornt) {
    switch (fornt) {
      case 'Red':
        return '#FFFFFF';
      case 'Yellow':
        return '#000000';
      case 'Green':
        return '#FFFFFF';
      case 'White':
        return '#000000';
      case 'Black':
        return '#FFFFFF';
      default:
        return '';
    }
  }
  constructor(private planningstockservice: PlanningStockService) {
  }

  ngOnInit() {
    this.statusList$ = this.planningstockservice.getStockStatus(this.materialCode, this.locationCode).subscribe(data => {
      if (data) {
        const l = [];
        this.materialLocationStatusHistoryDataList = data['materialLocationStatusHistoryDataList']
        this.materialLocationStatusHistoryDataList.forEach(d => {
          d.date = d.date.split("T")[0]
          l.push(d)
        })
        this.sortWithColor = l.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      }
      this.sortWithColor.reverse();
    })
  }
  ngOnDestroy() {
    if (this.statusList$) {
      this.statusList$.unsubscribe();
    }
  }

}
