import { Component, OnInit, ChangeDetectionStrategy, Input, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-reports-molecule',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './reports-molecule.component.html',
  styleUrls: ['./reports-molecule.component.scss']
})
export class ReportsMoleculeComponent implements OnInit, AfterViewInit {
  @Input() data: any;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
  }

  safeHTML(content) {

    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  ngAfterViewInit() {
    //setTimeout(() => { this.setColour(); }, 5000)
  }

  setColour() {
    let iframe;
    iframe = document.getElementsByTagName("iframe");
    const colours = ['Red', 'Blue', 'Green', 'Yellow', 'White'];
    if (iframe && iframe.length > 0) {
      const elements = iframe[0].contentWindow.document.getElementsByClassName("ag-cell-value");
      if (elements && elements.length > 0) {
        elements.map(element => {
          const colour = colours.filter(colour => colour === element.innerText);
          colour && colour.length > 0 ? element.style.backgroundColor = element.innerText : '';
        })
      }
    }
  }
}
