import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { materialColumns } from '../../../../properties/tableConfig';
import { MaterialsService } from '../../../../services/materials.service';
import { MatTableDataSource } from '@angular/material/table';
import { CommonUtilityService } from '../../../../services/common-utility-service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { BulkUploadServiceService } from 'src/app/services/bulk-upload-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-material-table-molecule',
  templateUrl: './material-table-molecule.component.html',
  styleUrls: ['./material-table-molecule.component.scss']
})
export class MaterialTableMoleculeComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ELEMENT_DATA: any[];
  dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  displayedColumns = materialColumns;
  add = false;
  update = false;
  searchField;
  nextLink:string;
  nextMaterialList$ = new Subscription();
  page: string

  clearSearchField() {
    this.searchField = '';
    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }

  constructor(private service: MaterialsService, 
    private utilService: CommonUtilityService, 
    private searchService: SearchFilterService,
    private bulkService: BulkUploadServiceService) { }

  ngOnInit() {
    this.service.getAllMaterials().subscribe((res: any) => {
      this.ELEMENT_DATA = res.materials;
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
	  if(res) {
        this.page=res?._links?.next?.href?.split("?")[1];
       }
    });
    const accessRules = JSON.parse(this.utilService.getCookie('accessRules')).accessRules;
    accessRules.map(each => {
      if (each.entity === 'material') {
        this.add = each.create;
        this.update = each.update;
      }
    });
  }
  public doFilter = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    if(!value) {
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }  
       
    else this.searchService.searchFilter('material', value, 'code').subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<any>(res.materials);
    })
  }

  downLoadReport() {
    this.bulkService.downLoadReport('material');
  }
  
    getNextMaterial() {
    if(this.page) {
      this.nextMaterialList$ = this.service.getNextMaterial(this.page).subscribe((res: any) => {
        this.page = res?._links?.next?.href?.split("?")[1];
        this.ELEMENT_DATA.push(... res.materials);
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.nextPage();
      });
    }
  }
  
  
    pageChanged(event) {
    if (this.page) {
      if (!(event.previousPageIndex > event.pageIndex)) {
        this.getNextMaterial();
      } 
    }
  }
  
  ngOnDestroy() {
    this.nextMaterialList$.unsubscribe();
  }
}


