
<div class="dasboardTilesContainer" [ngStyle]="{'background-color': 'white '}" >
    <ng-container  *ngFor="let tile of dashboardTile">
    <div class="tile" (click)="navigateTo(tile)" [ngStyle]="{'background-color': 'white'}">
        <p class="tileName">{{tile.label}}</p>
        <div class="tileImg">
            <img src="../../../../assets/img/{{tile.report}}.png" width="100" height="100"/>
            <span class="tileValue">{{tile.value}}</span>
        </div>
    </div>
</ng-container>
</div>