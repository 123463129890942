import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-molecule',
  templateUrl: './table-molecule.component.html',
  styleUrls: ['./table-molecule.component.scss']
})
export class TableMoleculeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
