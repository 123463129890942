import { Component, OnInit } from '@angular/core';
import { MaterialsService } from '../../../../services/materials.service';
import { ParameterMgtService } from '../../../../services/parameter-mgt.service';
import { FormDataConstructorService } from '../../../../services/form-data-constructor.service';
import { materialParams } from '../../../../properties/material_params_config';
import { ParamsMgtLocations } from '../../../../properties/params_mgt_locations_config';
import { ParamsMgtStockCriteria } from '../../../../properties/params_mgt_stockcriteria_config';
import { ParamsMgtShareOfBusiness } from '../../../../properties/params_mgt_shareofbusiness_config';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {api} from '../../../../properties/endpoints';

@Component({
  selector: 'app-parameter-management-form-organism',
  templateUrl: './parameter-management-form-organism.component.html',
  styleUrls: ['./parameter-management-form-organism.component.scss']
})
export class ParameterManagementFormOrganismComponent implements OnInit {

  formData: any;
  fuctionality: string;
  navigateBack: string;
  formTitle: string;
  constructor(private materialService: MaterialsService,private formService: FormDataConstructorService,  private route: ActivatedRoute,
    private parameterService: ParameterMgtService, private router:Router, private snack: MatSnackBar) {
    this.route.queryParams.subscribe(params => {
      this.fuctionality = params && params.fuctionality;
      switch(this.fuctionality){
        case 'materials':
          this.formData = this.formService.formConstructor(materialParams);
          this.formTitle = 'Materials Form';
          this.navigateBack = '/parameter-mgt-material';
          break;
        case 'locations':
          this.formData = this.formService.formConstructor(ParamsMgtLocations);
          this.formTitle = 'Locations Form';
          this.navigateBack = '/parameter-mgt-locations';
          break;
        case 'stockcriteria':
          this.formData = this.formService.formConstructor(ParamsMgtStockCriteria);
          this.formTitle = 'Stock Criteria Form';
          this.navigateBack = '/parameter-mgt-stock-criteria';
          break;
        case 'shareofbusiness':
          this.formData = this.formService.formConstructor(ParamsMgtShareOfBusiness);
          this.formTitle = 'Share Of Business Form';
          this.navigateBack = '/parameter-mgt-share-of-business';
          break;
      }
    });
  }

  ngOnInit(): void {
  }

  submitForm(formValues){
    let createMetaDataApi;
    switch(this.fuctionality) { 
      case 'materials': 
         createMetaDataApi = `${api.host}/material/metadata/create`;
         this.navigateBack = '/parameter-mgt-material';
         break; 
      case 'locations':
         createMetaDataApi = `${api.host}/location/metadata/create`;
         this.navigateBack = '/parameter-mgt-locations';
         break; 
      case 'stockcriteria':
        createMetaDataApi = `${api.host}/stockcriteria/metadata/create`;
        this.navigateBack = '/parameter-mgt-stock-criteria';
        break;
      case 'shareofbusiness':
        createMetaDataApi = `${api.host}/shareofbusiness/metadata/create`;
        this.navigateBack = '/parameter-mgt-share-of-business';
        break;
      default: 
         break; 
   } 
    let resultJson = {};
    this.formData.map((eachFormData) => {
        resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value;
    });
    this.parameterService.saveParameterMetaData(createMetaDataApi,resultJson).subscribe((response)=> {
      const message = 'Added successfully' ;
      this.snack.open(message, 'close', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.router.navigate([this.navigateBack]);
    });
  }

}
