import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import {api} from '../properties/endpoints';


@Injectable({
  providedIn: 'root'
})
export class ShareOfBusinessService {

  constructor(private http: HttpClient) { }

  getAllShareOfBusiness() {
    const url = `${api.host}/shareofbusiness/all`;
    return this.http.get(url);
  }

  getShareOfBusinessForm() {
    const url = `${api.host}/shareofbusiness/metadata`;
    return this.http.get(url);
  }

  getShareOfBusinesssByMaterialAndLocationCodes(materialCode, locationCode){
     const url = `${api.host}/shareofbusiness/materialcode/${materialCode}/locationcode/${locationCode}`;
    return this.http.get(url);
  }
  getShareofBusinessById(id){
    const url = `${api.host}/shareofbusiness/sobid/${id}`;
    return this.http.get(url);
  }

  getShareOfBusinessAttributesByMaterialAndLocation(materialCode, locationCode){
     const url = `${api.host}/shareofbusiness/attributes/materialcode/${materialCode}/locationcode/${locationCode}`;
    return this.http.get(url);
  }

  getShareOfBusinessAttributes(sobId){
     const url = `${api.host}/shareofbusiness/attributes/id/${sobId}`;
    return this.http.get(url);
  }

  saveShareOfBusiness(resultJson): Observable<any[]>{
    const locationSaveApi = `${api.host}/shareofbusiness/save`;
    return this.http.post<any>(locationSaveApi, resultJson);
}

  saveShareOfBusinessAttributes(resultJsonAttributes): Observable<any[]>{
    const locationAttributeSaveApi = `${api.host}/shareofbusiness/update/attributes`;
    return this.http.post<any>(locationAttributeSaveApi, resultJsonAttributes);
  }

}
