import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {api} from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class SearchFilterService {

  constructor(private http: HttpClient) { }
  searchFilter(page,value, key)  {
    const url = `${api.host}/${page}/search`;
    let resultJsonAttributes = [];
    resultJsonAttributes.push({
      "key": key,
      "value":value,
      "operator":"eq"
    });
    return this.http.post<any>(url,resultJsonAttributes );
  }
}
