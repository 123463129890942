export const PlanningStock = [
  {
      id: '5f1058132ba72fb0d87ed31e',
      identifier: 'quantity',
      label: 'Quantity',
      dataType: 'String',
      optional: false
  }
  ,
  {
      id: '5f1058132ba72fb0d87ed31e',
      identifier: 'planneddate',
      label: 'plannedDate',
      dataType: 'TimeStamp',
      optional: false
  },
  {
    id: "608feee91302e9626463e70f",
    identifier: "supplier",
    label: "Supplier",
    dataType: "Enum",
    optional: false,
    values: []
}
];
