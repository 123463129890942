import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-reports-filter-molecule',
  templateUrl: './reports-filter-molecule.component.html',
  styleUrls: ['./reports-filter-molecule.component.scss']
})
export class ReportsFilterMoleculeComponent implements OnInit, OnChanges {
  @Input('locationFiltersData') locationFiltersData: any;
  @Input('filters') filters: any;
  @Input('reportsData') reportsData: any;
  @Input('reportName') reportName: any;
  @Output() getReportsByFilters = new EventEmitter<any>();
  @ViewChild('allLocationSelected') private allLocationSelected: MatOption;
  locationFilterValues = new FormControl();
  colorFilterValue = new FormControl();
  locationFilters: any = {};
  colorFilters = [];
  allOtherFilters: any[] = [];
  selectedFilters: any[] = [];
  selectedOtherFilterKey: any;
  selectedOtherFilterOperator: any;
  selectedOtherFilterValue: any;
  allOtherFiltersOperators: any[];
  selectedTechBufferKey;
  selectedEcoBufferKey;
  colorFiltersLocal = [
    {
      "key": "techBufferColour",
      "label": "Tech Status ",
      "dataType": "String",
      "values": [
        "Black",
        "Red",
        "Yellow",
        "Green",
        "White",
        "Black & Red"
      ]
    },
    {
      "key": "ecoBufferColour",
      "label": "Eco Status ",
      "dataType": "String",
      "values": [
        "Black",
        "Red",
        "Yellow",
        "Green",
        "White",
        "Black & Red"
      ]
    }
  ];
  selectAllDeafult: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
    setTimeout(()=> {
      this.toggleLocationAllSelection();
      this.addFilter();
    });
  }

  ngOnChanges() {
    this.locationFiltersData && this.locationFiltersData.map(locationFilter => {
      if (locationFilter.key === 'locationCode') {
        this.locationFilters = locationFilter;
      }
    });
    this.allOtherFilters = [];
    if(this.reportsData && this.reportsData.selectedFilters) {
      this.selectedFilters.map((data, i) => {
        this.reportsData.selectedFilters.map((response, j) => {
          if(data.key === response.key) {
            this.selectedFilters[i]['label'] = response.label;
          }
        })
      })
    }
    // this.colorFilters = [];
    if (this.filters) {
      this.createColorAndOtherFilters();
    }
  }

  addFilter() {
    let locationFilterFlag = false;
    let colorFilterFlag = false;
    this.selectedFilters.map((filter, index) => {
      if (filter.key === this.locationFilters.key) {
        locationFilterFlag = true;
        this.selectedFilters[index]["values"] = this.removeExtraZeroFromLocationFilter(this.locationFilterValues.value);
        this.selectedFilters[index]["operator"] = "in";
      } 
      // else if (this.colorFilters && filter.key === this.colorFilters.key) {
      //   colorFilterFlag = true;
      //   this.selectedFilters[index]['values'] = [this.colorFilterValue.value];
      // }
    });
    !locationFilterFlag && this.pushLocationFilter();
    this.pushColorFilter();
    this.pushAllOtherFilters();
    this.reportName === 'Buffer_Penetration_Report' && this.selectedFilters.map((filter, index) => {
      if (filter.key === 'techBufferColour' || filter.key === 'ecoBufferColour') {
        if(filter.value && filter.value.indexOf('&') > 0){
          let splittedValue = filter.value.split('&');
          this.selectedFilters[index]["values"] = [splittedValue[0].replace(' ', ''), splittedValue[1].replace(' ', '')];
          this.selectedFilters[index]["operator"] = "in";
          delete this.selectedFilters[index]["value"];
        };
      }
    })
    this.getReportsByFilters.emit(this.selectedFilters);
  }

  pushLocationFilter() {
    if (this.locationFilterValues.value && this.locationFilterValues.value.length > 0) {
      this.selectedFilters.push({
        "key": this.locationFilters.key,
        "values": this.removeExtraZeroFromLocationFilter(this.locationFilterValues.value),
        "operator": "in"
      });
    }
  }

  removeExtraZeroFromLocationFilter(lctnFilterValues) {
    return lctnFilterValues.filter(v => v !== 0);
  }

  pushColorFilter() {
    this.colorFilters.map(colorFilter => {
      let colorFilterExist = false;
      this.selectedFilters.map((filter, index) => {
        if(colorFilter.key === filter.key) {
          this.selectedFilters[index]= { ...colorFilter };
          colorFilterExist = true;
        }
      });
      if(!colorFilterExist) {
        this.selectedFilters.push({...colorFilter})
      }
    })
  }

  onColorChange(selectedColor, colorFilter, funtionality) {
    funtionality === 'techBuffer' ? this.selectedTechBufferKey = selectedColor : this.selectedEcoBufferKey = selectedColor;
    let colorFilterFlag = false;
    this.colorFilters.map((filter, index) => {
      if (colorFilter && filter.key === colorFilter.key) {
        colorFilterFlag = true;
        this.colorFilters[index]['value'] = selectedColor;
      }
    });
    if(!colorFilterFlag) {
      this.colorFilters.push({
        "key": colorFilter.key,
        "value": selectedColor,
        "operator": "eq",
      });
    }
  }

  createColorAndOtherFilters() {
    this.filters.map(filter => {
      // if (filter.key === 'techBufferColour') {
      //   this.colorFilters = filter;
      // } 
      if (filter.values == null) {
        this.allOtherFilters.push(filter);
      }
    })
  }

  pushAllOtherFilters() {
    let filterExist = false;
    if (!this.selectedOtherFilterKey || !this.selectedOtherFilterValue) {
      return false;
    }
    this.selectedFilters.map((filter, index) => {
      if (filter.key === this.selectedOtherFilterKey) {
        filterExist = true;
        // let value = this.selectedFilters[index]['value'];
        //   this.selectedFilters[index]['values'].push(this.selectedOtherFilterValue);
      }
    })

    if (!filterExist) {
      this.selectedFilters.push({
        "key": this.selectedOtherFilterKey,
        "value": this.selectedOtherFilterValue,
        "operator": this.selectedOtherFilterOperator,
      });
    }
    this.selectedOtherFilterKey = 'all';
    this.selectedOtherFilterValue = '';
    this.selectedOtherFilterOperator = '';
    this.allOtherFiltersOperators = [];
  }

  typeCheckOfString(value) {
    return typeof value === 'string';
  }

  closeFilter(index) {
    if (this.selectedFilters[index]) {
      if (this.selectedFilters[index]['key'] === 'techBufferColour') {
        this.selectedTechBufferKey = '';
      }else if (this.selectedFilters[index]['key'] === 'ecoBufferColour') {
        this.selectedEcoBufferKey = '';
      } else if (this.selectedFilters[index]['key'] === 'locationCode') {
        this.locationFilterValues.patchValue([...this.locationFilters.labelValues.map(item => item.key), 0]);
      }
    }
    let removedFilter = this.selectedFilters.splice(index, 1);
    let colorIndex;
    this.colorFilters.map((colorFilter, colorIndexLocal) => {
      if(removedFilter[0]['key'] === colorFilter.key) {
        colorIndex = colorIndexLocal;
      }
    })
    colorIndex !== undefined && this.colorFilters.splice(colorIndex, 1);
    this.getReportsByFilters.emit(this.selectedFilters);
  }

  locationOneSelected(all) {
    if (this.allLocationSelected.selected) {
      this.allLocationSelected.deselect();
      return false;
    }
    if (this.locationFilterValues.value.length == this.locationFilters?.labelValues.length)
      this.allLocationSelected.select();

  }

  toggleLocationAllSelection() {
    if (this.allLocationSelected.selected || this.selectAllDeafult) {
      this.selectAllDeafult = false;
      this.locationFilterValues.patchValue([...this.locationFilters.labelValues.map(item => item.key), 0]);
    } else {
      this.locationFilterValues.patchValue([]);
    }
  }

  onOtherKeyChanges() {
    this.allOtherFilters.map(filter => {
      if (filter.key === this.selectedOtherFilterKey) {
        if (filter.dataType === 'String') {
          this.allOtherFiltersOperators = [{
            "label": "Equals",
            "key": "eq"
          }, {
            "label": "Starts With",
            "key": "regex"
          }, {
            "label": "Contains",
            "key": "regex"
          }
          ];
        } else if (filter.dataType === 'Number') {
          this.allOtherFiltersOperators = [{
            "label": "Equals",
            "key": "eq"
          }, {
            "label": "Greater than",
            "key": "greaterthan"
          }, {
            "label": "Less than",
            "key": "lessthan"
          }
          ];
        }
      }
    })
  }

  getLocationName(locationCode){
    let result = '';
    this.locationFilters && this.locationFilters.labelValues.map(data => {
      if(data.key === locationCode) {
        result = ' - ' + data.label;
      }
    })
    return result;
  }


}
