import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
@Component({
  selector: 'app-home-page-template',
  templateUrl: './home-page-template.component.html',
  styleUrls: ['./home-page-template.component.scss']
})
export class HomePageTemplateComponent implements OnInit {
  dashboardReports: any = []

  
  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.authenticationService.getRoleDetail().subscribe(data => {
      if(data?.dashboardReports){
        this.dashboardReports = data.dashboardReports
      }      
    });
  }

}
