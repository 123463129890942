import { Injectable } from '@angular/core';
import {api} from '../properties/endpoints';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SsoCallbackService {

  constructor(private http: HttpClient) { }

  callBack(path) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };
    const data = { "callback_uri": `${path}` };
    return this.http.post(`${api.host}/marketplace/authorise/callback`, data, options)
}

}
