import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportsService } from 'src/app/services/reports.service';
@Component({
  selector: 'app-dashboard-tiles',
  templateUrl: './dashboard-tiles.component.html',
  styleUrls: ['./dashboard-tiles.component.scss']
})
export class DashboardTilesComponent implements OnInit {
  dashboardTile: any;
  constructor(private router: Router, private reportsService: ReportsService) { }

  ngOnInit(): void {
    this.reportsService.getDashboardTails().subscribe(data => {
      this.dashboardTile = data['dashboardTiles'];


    })

  }

  navigateTo(tile) {
    this.router.navigate(["report"], { queryParams: { 'reportName': tile.report } })
  }

}
