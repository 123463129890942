<div class="pt-2 pl-2">
    <button mat-button mat-raised-button color="primary" routerLink='{{navigateBack}}'>
        <span class="material-icons">
            arrow_back
        </span>
        Back
    </button>
</div>

<mat-toolbar>
    {{formTitle}}
</mat-toolbar>

<!-- <mat-toolbar *ngIf="fuctionality === 'materials'">Parameters Form</mat-toolbar>

<mat-toolbar *ngIf="fuctionality === 'locations'">Locations Form</mat-toolbar> -->

<app-dynamic-form-molecule *ngIf="formData" [formData]='formData' (submitForm)="submitForm($event)"></app-dynamic-form-molecule>
