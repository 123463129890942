export const menu = [
  {
    header: 'supplier communication',
    icon: 'widgets',
    child: [
      {
        name: 'Stock Planning',
        route: 'planningStock',
        entity: 'stockPlanning',
      }
    ]
  },
{
    header: 'parameter mangement',
    icon: 'widgets',
    child: [
        {
            name: 'Materials',
            route: 'material',
            entity: 'material',
        },
        {
            name: 'Buffer Criteria',
            route: 'stock',
            entity: 'stockcriteria'
        },
        { name: 'Locations',
        route: 'locations',
        entity: 'location' ,

      },
      {
        name: 'Share of Business',
        route: 'shareofbusiness',
        entity: 'shareofbusiness'
    },
      {
        name: 'Stock Forecast',
        route: 'suggestion',
        entity: 'dynamicnorm'
      },
      // {
      //   name: 'Stock Planning',
      //   route: 'planningStock',
      //   entity: 'stockPlanning',
      // }
    ]
},
{
    //header: 'Parameters Management',
    header: 'Attribute Management',
    icon: 'store_mall_directory',
    child: [
        { name: 'Materials', route: 'parameter-mgt-material' , entity: 'material' },
        { name: 'Locations', route: 'parameter-mgt-locations' , entity: 'location' },
        { name: 'Stock Criteria', route: 'parameter-mgt-stock-criteria' , entity: 'stockcriteria' },
        { name: 'Share Of Business Management', route: 'parameter-mgt-share-of-business' , entity: 'shareofbusiness' }
    ]
},
{
    header: 'User Management',
    icon : 'supervised_user_circle',
    child: [
        { name: 'Users', route: 'users' , entity: 'user' }
    ]
},
];




export const users = [
    {
        username: 'user@gstool.com',
        password: '123456'
    },
    {
        username: 'user@vnenterprise.com',
        password: '123456'
    },
    {
        username: 'user@goodwillindustries.com',
        password: '123456'
    },
    {
        username: 'warehousemanager@oceaniasolution.com',
        password: '123456'
    }
];

