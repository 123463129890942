import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {api} from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class ParameterMgtService {

  constructor(private http: HttpClient) { }

  getParameterLocationsMetaData() {
    //const api = '../../assets/mock/parameter_mgt_locations_allMetaData.json';
     const url = `${api.host}/location/metadata`;
    return this.http.get(url);
  }

  saveParameterMetaData(url, resultJson) {
    return this.http.put<any>(url, resultJson);
  }

  getParameterStockCriteriaMetaData() {
     const url = `${api.host}/stockcriteria/metadata`;
    return this.http.get(url);
  }

  getParameterShareOfBusinessMetaData() {
     const url = `${api.host}/shareofbusiness/metadata`;
    return this.http.get(url);
  }

}
