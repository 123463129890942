<!-- <mat-toolbar class="header">
</mat-toolbar> -->
<mat-card class="my-card col-md-4 mat-elevation-z8">
  <mat-card-content>
    <h1 class="text">Reset your password</h1>
    <form
      class="my-form text-center"
      [formGroup]="forgetPassword"
      (ngSubmit)="onSubmit()"
    >
      <mat-form-field class="form-field">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="text"
          formControlName="email"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && formValue.email.errors }"
        />
        <div *ngIf="submitted && formValue.email.errors" class="invalid-feedback">
          <div *ngIf="formValue.email.errors.required">Email is required</div>
        </div>
      </mat-form-field>
      <br />
      <div class="form-group">
        <button
          mat-raised-button
          color="primary"
          [disabled]="loading"
          class="btn btn-primary buttons"
        >
          Submit
        </button>
        <button
        mat-raised-button
        color="white"
        [disabled]="loading"
        class="btn btn-info action_btn"
        [routerLink]="['/']"
      >
        Cancel
      </button>
        <!-- <div *ngIf="error" class="alert alert-danger">{{ error }}</div> -->
      </div>
    </form>
  </mat-card-content>
</mat-card>
