import { Component, OnInit } from '@angular/core';
import { CommonUtilityService } from '../../../services/common-utility-service';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { EmitterService } from '../../../services/emitter.service';
import { users } from '../../../properties/menu';
import { menu } from '../../../properties/menu'; 
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-login-molecule',
  templateUrl: './login-molecule.component.html',
  styleUrls: ['./login-molecule.component.scss']
})
export class LoginMoleculeComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private emitter: EmitterService,
      private utilService: CommonUtilityService
  ) {
    if (this.authenticationService.currentUserValue) { 
        this.router.navigate(['/']);
    }
   }

  ngOnInit() {
      this.loginForm = this.formBuilder.group({
          username: ['', Validators.required],
          password: ['', Validators.required]
      });

      this.returnUrl =  '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  onSubmit() {
      this.submitted = true;
      if (this.loginForm.invalid) {
          return;
      }
      this.loading = true;
      this.authenticationService.login(this.f.username.value, this.f.password.value)
          .pipe(first())
          .subscribe(
              data => {
                 this.utilService.setCookie('accessRules', JSON.stringify(data));
                 this.router.navigate([this.returnUrl]);
                 this.emitter.isLoggedInCheck.next(true)
              },
              error => {
                  let errorStatus = error.error.status;
                  let errorMsg = errorStatus === 401 ? 'Invalid username or password' : 'Error occured';
                  this.error = errorMsg;
                  this.loading = false;
              });
  }

}
