import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import {api} from '../properties/endpoints';


@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(private http: HttpClient) { }

  getAllLocations() {
    const url = `${api.host}/location/all`;
    return this.http.get(url);
  }

  getNextLocation(page){
    const url = `${api.host}/location/all?${page}`;
    console.log(url);
    
    return this.http.get(url);
  }

  getLocationsForm() {
    // const api = '../../assets/mock/allMetaData.json';
    const url = `${api.host}/location/metadata`;
    return this.http.get(url);
  }

  getLocationByCode(code) {
    // const api = '../../assets/mock/getMaterialByCode.json';
    const url = `${api.host}/location/`+code;
    return this.http.get(url);
  }

  getLocationAttributes(code) {
    // const api = '../../assets/mock/getMaterialAttributes.json';
    const url = `${api.host}/location/`+code+'/attributes';
    return this.http.get(url);
  }

  saveLocation(formValues, formData, addFlag, id): Observable<any[]>{
    const locationSaveApi = `${api.host}/location/save`;
    const locationAttributeSaveApi = `${api.host}/location/update/attributes`;
    let resultJson = {};
    let resultJsonAttributes = [];
    resultJson['id'] = id;
    formData.map((eachFormData) => {
      if(eachFormData.dynamic) {
        const attributeId = addFlag ? null : eachFormData.id;
        resultJsonAttributes.push({
          'id': attributeId,
          'code': formValues['code'].value,
          'attributeName': eachFormData.identifier,
          'attributeValue': formValues[eachFormData.identifier].value
        });
      } else {
        resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value; 
        if(eachFormData.dataType == "Array") {
          resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value && formValues[eachFormData.identifier].value instanceof Array ? formValues[eachFormData.identifier].value : formValues[eachFormData.identifier].value.split(','); 
        }
      }
    });
    return forkJoin([this.http.post<any>(locationSaveApi, resultJson), this.http.post<any>(locationAttributeSaveApi,resultJsonAttributes)]);
}


}
