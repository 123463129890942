import { Injectable } from '@angular/core';
import { FormBase } from '../interfaces/formInterface';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class FormControlService {
  constructor() { }

  toFormGroup(data: FormBase<any>[] ) {
    const group: any = {};

    data.forEach(each => {
      group[each.identifier] = !each.optional ? new FormControl(each.value || '', Validators.required)
                                              : new FormControl(each.value || '');
    });
    return new FormGroup(group);
  }
}