<div class="margn">
  <table id="table1">
    <tr>
      <td><b>Material Code</b></td>
      <td>{{materialCode}}</td>
    </tr>
    <tr>
      <td><b>Location Code</b></td>
      <td>{{locationCode}}</td>
    </tr>
  </table>
  <table id="table2">
    <tr>
      <td><b>Material Name</b></td>
      <td>{{mName}}</td>
    </tr>
    <tr>
      <td><b>Location Name</b></td>
      <td>{{loc}}</td>
    </tr>
  </table>
</div>
