import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parameter-management-locations-organism',
  templateUrl: './parameter-management-locations-organism.component.html',
  styleUrls: ['./parameter-management-locations-organism.component.css']
})
export class ParameterManagementLocationsOrganismComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
