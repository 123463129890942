import { Routes } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';

import { LoginFormComponent } from './shared/organisms/login-form/login-form.component';
import { HomePageTemplateComponent } from './templates/home-page-template/home-page-template.component';
import { MaterialsTemplateComponent } from './templates/materials-template/materials-template.component';
import { LocationsTemplateComponent } from './templates/locations-template/locations-template.component';
// tslint:disable-next-line: max-line-length
import { LocationsFormOrganismComponent } from './shared/organisms/locations-management/locations-form-organism/locations-form-organism.component';
import { MyNavTemplateComponent } from './templates/my-nav-template/my-nav-template.component';
import { LoginMoleculeComponent } from './shared/molecules/login-molecule/login-molecule.component';
import { RegisterMoleculeComponent } from './shared/molecules/register-molecule/register-molecule.component';
// tslint:disable-next-line: max-line-length
import { MaterialFormOrganismComponent } from './shared/organisms/material-management/material-form-organism/material-form-organism.component';
// tslint:disable-next-line: max-line-length
import { ParameterManagementOrganismComponent } from './shared/organisms/parameters-management/parameter-management-material-organism/parameter-management-material-organism.component';
// tslint:disable-next-line: max-line-length
import { ParameterManagementFormOrganismComponent } from './shared/organisms/parameters-management/parameter-management-form-organism/parameter-management-form-organism.component';
// tslint:disable-next-line: max-line-length
import { ParameterManagementLocationsOrganismComponent } from './shared/organisms/parameters-management/parameter-management-locations-organism/parameter-management-locations-organism.component';
import { ParameterManagementStockCriteriaOrganismComponent } from './shared/organisms/parameters-management/parameter-management-stock-criteria-organism/parameter-management-stock-criteria-organism.component';
import { ParameterManagementShareOfBusinessOrganismComponent } from './shared/organisms/parameters-management/parameter-management-share-of-business-organism/parameter-management-share-of-business-organism.component';
import { ReportsOrganismComponent } from './shared/organisms/reports/reports-organism/reports-organism.component';
import { StockCriteriaComponent } from './shared/organisms/stock-management/stock-criteria/stock-criteria.component';
import { StockFormOrganismComponent } from './shared/organisms/stock-management/stock-form-organism/stock-form-organism.component';
// tslint:disable-next-line: max-line-length
import { ShareOfBusinessOrganismComponent } from './shared/organisms/shareofbusiness-management/shareofbusiness-organism/shareofbusiness-organism.component';
import { DynamicNormOrganismComponent } from './shared/organisms/dynamicnorm-management/dynamicnorm-organism/dynamicnorm-organism.component';
// tslint:disable-next-line: max-line-length
import { ShareOfBusinessFormOrganismComponent } from './shared/organisms/shareofbusiness-management/shareofbusiness-form-organism/shareofbusiness-form-organism.component';
import { UserOrganismComponent } from './shared/organisms/user-management/user-organism/user-organism.component';
import { UserFormOrganismComponent } from './shared/organisms/user-management/user-form-organism/user-form-organism.component';
import { BulkUploadOrganismComponent } from './shared/organisms/bulk-upload-organism/bulk-upload-organism.component';
import { SsoCallbackComponent } from './shared/organisms/sso-callback/sso-callback.component'
import { ForgetPasswdComponent } from './shared/molecules/forget-passwd/forget-passwd.component'
import { PlanningStockTableMoleculeComponent } from './shared/molecules/planning-stock-table-molecule/planning-stock-table-molecule.component'
import { InnerPlanningstockComponent } from './shared/molecules/inner-planningstock/inner-planningstock.component'

export const routes: Routes = [
    // {
    //     path: '',
    //     component: LoginFormComponent,
    // },
    {
        path: 'login',
        component: LoginMoleculeComponent,
    },
    {
        path: 'register',
        component: RegisterMoleculeComponent,
    },
    {
        path: 'sso/callback/:id',
        component: SsoCallbackComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'passwordReset',
        component: ForgetPasswdComponent,
    },
    {
        path: 'material',
        component: MaterialsTemplateComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'planningStock',
        component: PlanningStockTableMoleculeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'iplanningStock',
        component: InnerPlanningstockComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'materialForm',
        component: MaterialFormOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'locations',
        component: LocationsTemplateComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'locationsForm',
        component: LocationsFormOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'parameter-mgt-material',
        component: ParameterManagementOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'parameter-mgt-locations',
        component: ParameterManagementLocationsOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'parameter-mgt-stock-criteria',
        component: ParameterManagementStockCriteriaOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'parameter-mgt-share-of-business',
        component: ParameterManagementShareOfBusinessOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'parameterForm',
        component: ParameterManagementFormOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'report',
        component: ReportsOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'stock',
        component: StockCriteriaComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'stockForm',
        component: StockFormOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'shareofbusiness',
        component: ShareOfBusinessOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'shareofbusinessForm',
        component: ShareOfBusinessFormOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'suggestion',
        component: DynamicNormOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'users',
        component: UserOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'userForm',
        component: UserFormOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path:'bulkUpload',
        component: BulkUploadOrganismComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        component: HomePageTemplateComponent,
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'dashboard',
    //     component: MyNavTemplateComponent,
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: '**',
    //     redirectTo: '404',
    //     data: [{
    //       pageName: 'Not Found',
    //     },
    //     {
    //         path: '404',
    //         component: NotFoundOrganismComponent,
    //         pathMatch: 'full',
    //         data: [{
    //           pageName: 'Not Found',
    //     }
];

