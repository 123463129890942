import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { parameterColumns, parameterLocationColumns, parameterStockCriteriaColumns, parameterShareOfBusinessColumns } from '../../../../properties/tableConfig';
import { MaterialsService } from '../../../../services/materials.service';
import { ParameterMgtService } from '../../../../services/parameter-mgt.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-parameter-table-molecule',
  templateUrl: './parameter-table-molecule.component.html',
  styleUrls: ['./parameter-table-molecule.component.scss']
})
export class ParameterTableMoleculeComponent implements OnInit {
  @Input() fuctionality: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   @ViewChild(MatSort, { static: true }) sort: MatSort;
   ELEMENT_DATA: any[];
   dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
   displayedColumns: string[];

  constructor(private service: MaterialsService, private paramMgtService: ParameterMgtService) {}

  ngOnInit() {
    switch(this.fuctionality){
      case 'locations':
        this.displayedColumns = parameterLocationColumns;
        this.paramMgtService.getParameterLocationsMetaData().subscribe((res: any) => {
          this.ELEMENT_DATA = res.metaData;
          this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        });
        break;
      case 'materials':
        this.displayedColumns = parameterColumns;
        this.service.getMaterialForm().subscribe((res: any) => {
          this.ELEMENT_DATA = res.metaData;
          this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        });
        break;
      case 'stockcriteria':
        this.displayedColumns = parameterStockCriteriaColumns;
        this.paramMgtService.getParameterStockCriteriaMetaData().subscribe((res: any) => {
          this.ELEMENT_DATA = res.metaData;
          this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        });
        break;
      case 'shareofbusiness':
        this.displayedColumns = parameterShareOfBusinessColumns;
        this.paramMgtService.getParameterShareOfBusinessMetaData().subscribe((res: any) => {
          this.ELEMENT_DATA = res.shareOfBusinessMetadataList;
          this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        });
        break;
    }
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
}
