import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../../material.module';
import { ServicesModule } from '../../services/services.module';
import { LoginMoleculeComponent } from './login-molecule/login-molecule.component';
import { RegisterMoleculeComponent } from './register-molecule/register-molecule.component';
import { MaterialTableMoleculeComponent } from './material-management/material-table-molecule/material-table-molecule.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DynamicFormMoleculeComponent } from './dynamic-form-molecule/dynamic-form-molecule.component';
import { DynamicFormFieldMoleculeComponent } from './dynamic-form-field-molecule/dynamic-form-field-molecule.component';
import { ParameterTableMoleculeComponent } from './parameter-management/parameter-table-molecule/parameter-table-molecule.component';
import { ReportsMoleculeComponent } from './reports-molecule/reports-molecule.component';
import { StockCriteriaTableComponent } from './stock-management/stock-criteria-table/stock-criteria-table.component';
import { ShareOfBusinessTableMoleculeComponent } from './shareofbusiness-management/shareofbusiness-table-molecule/shareofbusiness-table-molecule.component';
import { DynamicNormTableMoleculeComponent } from './dynamicnorm-management/dynamicnorm-table-molecule/dynamicnorm-table-molecule.component';
import { LocationsTableMoleculeComponent } from './locations-management/locations-table-molecule/locations-table-molecule.component';
import { TableMoleculeComponent } from './table-molecule/table-molecule.component';
import { UserTableMoleculeComponent } from './user-managment/user-table-molecule/user-table-molecule.component';
import { BulkUploadMoleculeComponent } from './bulk-upload-molecule/bulk-upload-molecule.component';
import { ReportsFilterMoleculeComponent } from './reports-filter-molecule/reports-filter-molecule.component';
import { LoaderComponent } from './loader/loader.component';
import { ForgetPasswdComponent } from './forget-passwd/forget-passwd.component';
import { PlanningStockTableMoleculeComponent } from './planning-stock-table-molecule/planning-stock-table-molecule.component';
import { InnerPlanningstockComponent } from './inner-planningstock/inner-planningstock.component';
import { BprStatusHistoryComponent } from './bpr-status-history/bpr-status-history.component';
import { StockPlanningTabComponent } from './stock-planning-tab/stock-planning-tab.component';
import { StockPlanningTableComponent } from './stock-planning-table/stock-planning-table.component';


const components =[
  LoginMoleculeComponent ,
  RegisterMoleculeComponent,
  MaterialTableMoleculeComponent,
  DynamicFormMoleculeComponent,
  DynamicFormFieldMoleculeComponent,
  ParameterTableMoleculeComponent,
  ReportsMoleculeComponent,
  StockCriteriaTableComponent,
  LocationsTableMoleculeComponent,
  TableMoleculeComponent,
  UserTableMoleculeComponent,
  ShareOfBusinessTableMoleculeComponent,
  DynamicNormTableMoleculeComponent,
  BulkUploadMoleculeComponent,
  ReportsFilterMoleculeComponent,
  LoaderComponent
];
@NgModule({
  declarations: [...components, ForgetPasswdComponent, PlanningStockTableMoleculeComponent, InnerPlanningstockComponent, BprStatusHistoryComponent, StockPlanningTabComponent, StockPlanningTableComponent],
  imports: [
    CommonModule,
    LayoutModule,
    RouterModule,
    MaterialModule,
    ServicesModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [...components]
})
export class MoleculesModule { }
