import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import {api} from '../properties/endpoints';


@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private http: HttpClient) { }

  getAllStock() {
    //const api = '../../assets/mock/getAllStock.json';
    const url = `${api.host}/stockcriteria/all`;
    return this.http.get(url);
  }
  getNextStockCriteria(url){
    return this.http.get(url);
  }
  getStockForm() {
    // const api = '../../assets/mock/getStockMetaData.json';
    const url = `${api.host}/stockcriteria/metadata`;
    return this.http.get(url);
  }

  getStocksByMaterialAndLocationCodes(materialCode, locationCode){
    //const api = '../../assets/mock/getStocksByMaterialAndLocationCodes.json';
     const url = `${api.host}/stockcriteria/materialcode/${materialCode}/locationcode/${locationCode}`;
    return this.http.get(url);
  }

  getStockAttributesByMaterialAndLocation(materialCode, locationCode){
    //const api = '../../assets/mock/getStockAttributesByMaterialAndLocation.json';
     const url = `${api.host}/stockcriteria/attributes/materialcode/${materialCode}/locationcode/${locationCode}`;
    return this.http.get(url);
  }

  saveStock(formValues, formData, addFlag, id): Observable<any[]>{
    const locationSaveApi = `${api.host}/stockcriteria/save`;
    const locationAttributeSaveApi = `${api.host}/stockcriteria/update/attributes`;
    let resultJson = {};
    let resultJsonAttributes = [];
    resultJson['id'] = id;
    formData.map((eachFormData) => {
      if(eachFormData.dynamic) {
        const attributeId = addFlag ? null : eachFormData.id;
        resultJsonAttributes.push({
          'id': attributeId,
          'materialCode': formValues['materialCode'].value,
          'locationCode': formValues['locationCode'].value,
          'attributeName': eachFormData.identifier,
          'attributeValue': formValues[eachFormData.identifier].value
        });
      } else {
        resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value; 
        if(eachFormData.dataType == "Array") {
          resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value && formValues[eachFormData.identifier].value instanceof Array ? formValues[eachFormData.identifier].value : formValues[eachFormData.identifier].value.split(','); 
        }
      }
    });
    return forkJoin([this.http.post<any>(locationSaveApi, resultJson), this.http.post<any>(locationAttributeSaveApi,resultJsonAttributes)]);
}

}
