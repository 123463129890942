<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav vertical-nav" fixedInViewport
      [attr.role]="((isHandset$ | async) || !isLoggedIn) ? 'dialog' : 'navigation'"
      [mode]="((isHandset$ | async) || !isLoggedIn) ? 'over' : 'side'"
      [opened]="((isHandset$ | async) || !isLoggedIn) === false">
    <mat-toolbar class="dashboard_leftMenu mat-elevation-z6"  color="primary">
      <mat-icon  style="cursor:pointer" (click)="drawer.toggle()" aria-label="Side nav toggle icon">menu</mat-icon>
      <span style="margin-left:15px;">Menu</span>
      <!-- <span class='title' routerLink=''>
        <img class="col-6 img-fluid" src="assets/img/logo.png" alt="logo"/></span> -->
    </mat-toolbar>
    <mat-accordion>
      <ng-container *ngFor='let category of menu; let parentIndex = index;'>
      <mat-expansion-panel  *ngIf="category.enabled"
      [expanded]="togFunc(parentIndex)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="nav-heading" fxLayout="row" fxLayoutAlign="space-between center" >
             <mat-icon class="dashboard-menu-icon">
              {{category.icon}}
             </mat-icon>
              <span class="menu-title">{{category.header}}</span>
            </div>
          </mat-panel-title>
          </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item *ngFor="let subCat of category.child | accessRules : category.header; let childIndex = index;">
             <a matLine id="nav-{{parentIndex}}-{{childIndex}}" (click)="navigate(subCat)" >{{subCat.name}}
              <button *ngIf="!subCat.authorised && ts(subCat.name)"
              mat-raised-button
              color="primary"
              class="btn btn-primary"
              (click)="login(subCat.authorise_url)"
              style="left: 50px;"
              >
              authorize
              </button>
               <span *ngIf="subCat.authorised && ts(subCat.name)"
               style="color: green; font-size: 20px;float: right;font-weight: bold"
               >
                &#x2713;
               </span>
             </a>
          </mat-list-item>
        </mat-nav-list>
      </mat-expansion-panel>
    </ng-container>
    </mat-accordion>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary" class="mat-elevation-z6">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="(!drawer.opened && isLoggedIn)">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class='title' routerLink=''>
        <img class="col-6 img-fluid" src="assets/img/logo.png" alt="logo"/></span>
      <div class="nav-links">
        <ul class="row top_nav_link">
          <li *ngIf='isLoggedIn'>
            <span class="greeting">Welcome {{getName()}}</span>
          </li>
          <li  *ngIf='isLoggedIn'>
              <button mat-button [matMenuTriggerFor]="menu_app">
                  <mat-icon class="account_icon account_circle">account_circle</mat-icon>
              </button>
              <mat-menu #menu_app="matMenu">
                  <button mat-menu-item (click)='logout()'>
                      <mat-icon>exit_to_app</mat-icon>
                      Sign out
                  </button>
              </mat-menu>
          </li>
          <!-- <li  *ngIf='!isLoggedIn'>
            <button mat-button>
                Login
            </button>
        </li> -->
        </ul>
    </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
