<ng-container *ngIf="!hideFilters">
    <mat-toolbar class="header display-flex justify-content-center">
        <span>{{reportNameWithSpace}}</span>
    </mat-toolbar>
    <div *ngIf="locationFiltersData">
        <app-reports-filter-molecule [filters]="filters" [reportsData]="reportsData" [reportName]="reportName"
            [locationFiltersData]="locationFiltersData" (getReportsByFilters)="getReportsByFilters($event)">
        </app-reports-filter-molecule>
    </div>

    <button *ngIf="iFrameData?.length > 0" mat-button mat-raised-button color="primary"
        class="d-flex align-items-center mb-2 ml-2" (click)="downLoadReport()">
        Download Report
    </button>
</ng-container>

<div *ngIf="iFrameData?.length > 0">
    <div *ngFor="let data of iFrameData; let index=index;">
        <app-reports-molecule [data]='data'></app-reports-molecule>
    </div>
</div>
