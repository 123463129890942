import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parameter-management-organism',
  templateUrl: './parameter-management-material-organism.component.html',
  styleUrls: ['./parameter-management-material-organism.component.scss']
})
export class ParameterManagementOrganismComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
