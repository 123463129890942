import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { api } from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getAllUsers() {
    // const api = '../../assets/mock/all_materials.json';
    const url = `${api.host}/user/all`;
    return this.http.get(url);
  }
  getNextUsers(pages) {
    const url = `${api.host}/user/all` + `?${pages}`;
    return this.http.get(url);
  }
  getUserForm() {
    // const api = '../../assets/mock/allMetaData.json';
    const url = `${api.host}/user/metadata`;
    return this.http.get(url);
  }
  getUserById(userId) {
    // const api = '../../assets/mock/getMaterialByCode.json';
    const url = `${api.host}/user/${userId}`;
    return this.http.get(url);
  }
  getUserAttributes(userId) {
    const apiLocal = '../../assets/mock/getMaterialAttributes.json';
    const url = `${api.host}/user/${userId}`;

    return this.http.get(url);
  }

  getMarketplace(){
    const url=`${api.host}/marketplace/all`
    return this.http.get(url);
  }
  resetPassword(email) {
    const url = `${api.host}/user/password/reset`;
    return this.http.post<any>(url, email)
  }
  saveUser(formValues, formData, addFlag, id): Observable<any[]> {
    const userCreateApi = `${api.host}/user/create`;
    const userUpdateApi = `${api.host}/user/update?pwdChange=false`;
    const userAttributeSaveApi = `${api.host}/user/update/attributes`;
    const resultJson = {};
    const resultJsonAttributes = [];
    resultJson['id'] = id;
    formData.map((eachFormData) => {
      if (eachFormData.dynamic) {
        const attributeId = addFlag ? null : eachFormData.id;
        resultJsonAttributes.push({
          'id': attributeId,
          'userId': formValues['userId'].value,
          'attributeName': eachFormData.identifier,
          'attributeValue': formValues[eachFormData.identifier].value
        });
      } else {
        resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value;
        if (eachFormData.dataType === "Array") {
          resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value && formValues[eachFormData.identifier].value instanceof Array ? formValues[eachFormData.identifier].value : formValues[eachFormData.identifier].value.split(',');
        }
      }
    });

    return forkJoin([addFlag ? this.http.put<any>(userCreateApi, resultJson) : this.http.post<any>(userUpdateApi, resultJson), this.http.post<any>(userAttributeSaveApi, resultJsonAttributes)]);
  }

}
