import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { shareOfBusinessColumns } from '../../../../properties/tableConfig';
import { ShareOfBusinessService } from '../../../../services/shareofbusiness.service';
import { MatTableDataSource } from '@angular/material/table';
import { CommonUtilityService } from '../../../../services/common-utility-service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { BulkUploadServiceService } from 'src/app/services/bulk-upload-service.service';
@Component({
  selector: 'app-shareofbusiness-table-molecule',
  templateUrl: './shareofbusiness-table-molecule.component.html',
  styleUrls: ['./shareofbusiness-table-molecule.component.scss']
})
export class ShareOfBusinessTableMoleculeComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ELEMENT_DATA: any[];
  dataSource:MatTableDataSource<any>;
  displayedColumns = shareOfBusinessColumns;
  add = false;
  update = false;
  constructor(private service: ShareOfBusinessService, 
    private utilService: CommonUtilityService, 
    private searchService: SearchFilterService,
    private bulkService: BulkUploadServiceService) {}
  searchField;

  clearSearchField() {
    this.searchField = '';
    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }

  ngOnInit() {
   this.service.getAllShareOfBusiness().subscribe((res: any) => {
      this.ELEMENT_DATA = res.shareOfBusinessList;
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
   });
   const accessRules = JSON.parse(this.utilService.getCookie('accessRules')).accessRules;
   accessRules.map(each => {
    if (each.entity === 'shareofbusiness') {
        this.add = each.create;
        this.update = each.update;
    }
   });
  }
  public doFilter = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    if(!value) {
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }  
       
    else this.searchService.searchFilter('shareofbusiness', value, 'materialCode').subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<any>(res.shareOfBusinessList);
    })
  }

  downLoadReport() {
    this.bulkService.downLoadReport('shareofbusiness');
  }

}
