import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services/user.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forget-passwd',
  templateUrl: './forget-passwd.component.html',
  styleUrls: ['./forget-passwd.component.scss']
})
export class ForgetPasswdComponent implements OnInit {
  public forgetPassword: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  forgetPwd$ = new Subscription();
  constructor(private formBuilder: FormBuilder,
    private userservice: UserService,
    private toastr: ToastrService,
    private router: Router
  ) { }
  ngOnInit() {
    this.forgetPassword = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }
  get formValue() { return this.forgetPassword.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.forgetPassword.invalid) {
      return;
    }
    this.loading = true;
    this.forgetPwd$ = this.userservice.resetPassword(this.formValue.email.value).subscribe(
      (res) => {
      },
      (error) => {
        if (error.error.text === "Success") {
          this.loading = false;
          this.router.navigate(['/'])
          this.toastr.success(`Mail sent successfully to ${this.formValue.email.value}`);
        } else {
          this.loading = false;
          this.toastr.error("Failed to send mail, please check your Email and re-try again").onShown;
          this.router.navigate(['/passwordReset'])
        }
      }
    );
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.forgetPwd$.unsubscribe();
    }, 9000);
  }

}
