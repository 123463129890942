import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MoleculesModule } from '../molecules/molecules.module';
import { LoginFormComponent } from './login-form/login-form.component';
import { MaterialModule } from '../../material.module';
import { MaterialOrganismComponent } from './material-management/material-organism/material-organism.component';
import { MaterialFormOrganismComponent } from './material-management/material-form-organism/material-form-organism.component';
// tslint:disable-next-line: max-line-length
import { ParameterManagementOrganismComponent } from './parameters-management/parameter-management-material-organism/parameter-management-material-organism.component';
// tslint:disable-next-line: max-line-length
import { ParameterManagementFormOrganismComponent } from './parameters-management/parameter-management-form-organism/parameter-management-form-organism.component';
// tslint:disable-next-line: max-line-length
import { ParameterManagementLocationsOrganismComponent } from './parameters-management/parameter-management-locations-organism/parameter-management-locations-organism.component';
import { ReportsOrganismComponent } from './reports/reports-organism/reports-organism.component';
import { StockCriteriaComponent } from './stock-management/stock-criteria/stock-criteria.component';
import { StockFormOrganismComponent } from './stock-management/stock-form-organism/stock-form-organism.component';
import { ShareOfBusinessOrganismComponent } from './shareofbusiness-management/shareofbusiness-organism/shareofbusiness-organism.component';
import { ShareOfBusinessFormOrganismComponent } from './shareofbusiness-management/shareofbusiness-form-organism/shareofbusiness-form-organism.component';
import { DynamicNormOrganismComponent } from './dynamicnorm-management/dynamicnorm-organism/dynamicnorm-organism.component';
import { LocationsOrganismComponent } from './locations-management/locations-organism/locations-organism.component';
import { LocationsFormOrganismComponent } from './locations-management/locations-form-organism/locations-form-organism.component';
import { UserOrganismComponent } from './user-management/user-organism/user-organism.component';
import { UserFormOrganismComponent } from './user-management/user-form-organism/user-form-organism.component';
import { ParameterManagementStockCriteriaOrganismComponent } from './parameters-management/parameter-management-stock-criteria-organism/parameter-management-stock-criteria-organism.component';
import { ParameterManagementShareOfBusinessOrganismComponent } from './parameters-management/parameter-management-share-of-business-organism/parameter-management-share-of-business-organism.component';
import { BulkUploadOrganismComponent } from './bulk-upload-organism/bulk-upload-organism.component';
import { DashboardTilesComponent } from './dashboard-tiles/dashboard-tiles.component';
import { SsoCallbackComponent } from './sso-callback/sso-callback.component';

const components = [
  LoginFormComponent,
  MaterialOrganismComponent,
  MaterialFormOrganismComponent,
  ParameterManagementOrganismComponent,
  ParameterManagementFormOrganismComponent,
  ParameterManagementLocationsOrganismComponent,
  ReportsOrganismComponent,
  StockCriteriaComponent,
  StockFormOrganismComponent,
  ShareOfBusinessOrganismComponent,
  ShareOfBusinessFormOrganismComponent,
  DynamicNormOrganismComponent,
  LocationsOrganismComponent,
  LocationsFormOrganismComponent,
  UserOrganismComponent,
  UserFormOrganismComponent,
  ParameterManagementStockCriteriaOrganismComponent,
  ParameterManagementShareOfBusinessOrganismComponent,
  BulkUploadOrganismComponent,
  DashboardTilesComponent
];
@NgModule({
imports: [
  CommonModule,
  MoleculesModule,
  MaterialModule,
  RouterModule
],
exports: [
  ...components
],
declarations: [... components, SsoCallbackComponent],

})
export class OrganismsModule { }
