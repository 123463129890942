import { Component, OnInit, ViewChild } from '@angular/core';
import { planningStockColumns } from '../../../properties/tableConfig';
import { PlanningStockService } from '../../../services/planning-stock.service'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SearchFilterService } from 'src/app/services/search-filter.service';
@Component({
  selector: 'app-planning-stock-table-molecule',
  templateUrl: './planning-stock-table-molecule.component.html',
  styleUrls: ['./planning-stock-table-molecule.component.scss']
})
export class PlanningStockTableMoleculeComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ELEMENT_DATA: any[];
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns = planningStockColumns
  add = false;
  update = true;
  selection;
  page: any;
  constructor(private planningstockservice: PlanningStockService,
    private searchService: SearchFilterService) { }
  searchField;

  clearSearchField() {
    this.searchField = '';
    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }
  addSpaces(str: string): string {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');

  }
  ngOnInit() {
    this.planningstockservice.getPlanningServices().subscribe(res => {
      this.ELEMENT_DATA = res['materialLocationEntityList'];
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  getBackgroundColor(color) {
    switch (color) {
      case 'Red':
        return '#C10422';
      case 'Yellow':
        return '#E6B219';
      case 'Green':
        return '#116149';
      case 'White':
        return '#FFFFFF';
      case 'Black':
        return '#000000';
      default:
        return '';
    }
  }
  getTextColor(fornt) {
    switch (fornt) {
      case 'Red':
        return '#FFFFFF';
      case 'Yellow':
        return '#000000';
      case 'Green':
        return '#FFFFFF';
      case 'White':
        return '#000000';
      case 'Black':
        return '#FFFFFF';
      default:
        return '';
    }
  }
  public doFilter = (value: string) => {
    if (!value) {
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    else this.searchService.searchFilter('stockplanning/materiallocation', value, 'materialCode').subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<any>(res['materialLocationEntityList']);
    })
  }

}
