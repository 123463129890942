import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { dynamicNormColumns } from '../../../../properties/tableConfig';
import { DynamicNormService } from '../../../../services/dynamicnorm.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { CommonUtilityService } from '../../../../services/common-utility-service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { BulkUploadServiceService } from 'src/app/services/bulk-upload-service.service';
import {  Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dynamicnorm-table-molecule',
  templateUrl: './dynamicnorm-table-molecule.component.html',
  styleUrls: ['./dynamicnorm-table-molecule.component.scss']
})
export class DynamicNormTableMoleculeComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ELEMENT_DATA: any[];
  dataSource = new MatTableDataSource<any>([]);
  dataColumns = dynamicNormColumns;
  displayedColumns = ['select',...dynamicNormColumns];
  add = false;
  update = false;
  selection;
  page:any;
  nextDynamicList$ = new Subscription();

  constructor(private service: DynamicNormService,
    private utilService: CommonUtilityService,
    private searchService: SearchFilterService,
    private bulkService: BulkUploadServiceService,
    private snack: MatSnackBar,
    private router:Router) {}
  searchField;

  clearSearchField() {
    this.searchField = '';
    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }

  ngOnInit() {
   this.service.getAllDynamicNorm().subscribe((res: any) => {
      this.ELEMENT_DATA = res.dynamicNormList;
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      if(res){
        this.page=res?._links?.next?.href?.split("?")[1];
        console.log(this.page);
      }
   });
   const initialSelection = [];
   const allowMultiSelect = true;
   this.selection = new SelectionModel<any>(allowMultiSelect, initialSelection);
   const accessRules = JSON.parse(this.utilService.getCookie('accessRules')).accessRules;
   accessRules.map(each => {
    if (each.entity === 'dynamicnorm') {
        this.add = each.create;
        this.update = each.update;
    }
   });
  }
  getNextPage(){
    if(this.page) {
      this.nextDynamicList$ = this.service.nextPage(this.page).subscribe((res: any) => {
        //this.nextLink = res?._links?.next?.href;
        console.log(this.page);
        this.page=res?._links?.next?.href?.split("?")[1];
        this.ELEMENT_DATA.push(... res.locations);
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.nextPage();
      });
    }

  }
  public publishSelected = () => {
    const selectedRows = this.selection.selected;
    if(selectedRows.length > 0 ){
      this.service.publishSelectedNorm(selectedRows).subscribe((response)=> {
        const message = 'Publish completed successfully' ;
        this.snack.open(message, 'close', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      },(error) => {
        console.log(error);
      },()=>{
        this.ngOnInit();
      });
    }
  }



  public isAtleastOneSelected = () => {
    return this.selection.selected.length > 0;
  }

  public isAllSelected = () => {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle = () => {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public doFilter = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    if(!value) {
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }

    else this.searchService.searchFilter('suggestion', value, 'materialCode').subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<any>(res.dynamicNormList);
    })
  }

  downLoadReport() {
    this.bulkService.downLoadReport('suggestion');
  }
  pageChanged(event) {
    if (this.page) {
      if (!(event.previousPageIndex > event.pageIndex)) {
        this.getNextPage();
      }
    }
  }
  ngOnDestroy() {
    this.nextDynamicList$.unsubscribe();
  }
}
