<div class="pt-2 pl-2">
    <button mat-button mat-raised-button color="primary" routerLink='/shareofbusiness'>
        <span class="material-icons">
            arrow_back
        </span>
        Back
    </button>
</div>

<mat-toolbar>Share Of Business Form</mat-toolbar>

<app-dynamic-form-molecule *ngIf="formData" [formData]='formData' [addFlag]='addFlag'  (submitForm)="submitForm($event)"></app-dynamic-form-molecule>
