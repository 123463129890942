<div style="margin: auto;margin-top: 50px; text-align: center;" *ngIf="sortWithColor!=''">
  <h2>Timeline View - Stock Status</h2>
</div>
<div class="scroll">
<table style="margin: auto">
  <thead>
    <tr>
      <th *ngFor="let box of sortWithColor">{{box.date| date:'dd MMM yyyy' }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td *ngFor="let box of sortWithColor"
        [ngStyle]="{'background-color': box.status,'color':getTextColor(box.status)}">{{ box.status}}</td>
    </tr>
  </tbody>
</table>
</div>
<section *ngIf="sortWithColor!=''"></section>
