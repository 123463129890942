import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-molecule',
  templateUrl: './register-molecule.component.html',
  styleUrls: ['./register-molecule.component.scss']
})
export class RegisterMoleculeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
