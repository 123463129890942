import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanningStockService } from 'src/app/services/planning-stock.service';
@Component({
  selector: 'app-inner-planningstock',
  templateUrl: './inner-planningstock.component.html',
  styleUrls: ['./inner-planningstock.component.scss'],
})
export class InnerPlanningstockComponent implements OnInit {
  materialCode;
  locationCode;
  add = true;
  update = true;
  selection;
  page: any;
  formData: any;
  addFlag: any = true;
  fm = false;
  create() {
    this.fm = true;
  }
  constructor(private route: ActivatedRoute,
    private planningstockservice: PlanningStockService,
    private snack: MatSnackBar, private router: Router) {

  }

  ngOnInit() {
    this.materialCode = this.route.snapshot.queryParamMap.get('Mcode');
    this.locationCode = this.route.snapshot.queryParamMap.get('Lcode');
  }
}
