import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-locations-template',
  templateUrl: './locations-template.component.html',
  styleUrls: ['./locations-template.component.scss']
})
export class LocationsTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
