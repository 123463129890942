import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBase } from '../../../interfaces/formInterface';

@Component({
  selector: 'app-dynamic-form-field-molecule',
  templateUrl: './dynamic-form-field-molecule.component.html',
  styleUrls: ['./dynamic-form-field-molecule.component.scss']
})
export class DynamicFormFieldMoleculeComponent implements OnInit, OnChanges {
  @Input() fieldData: FormBase<any>;
  @Input() form: FormGroup;
  currentDate = new Date();
  constructor() { }

  ngOnChanges() {
  }

  ngOnInit() {
  }
  get isValid() { return this.form.controls[this.fieldData.identifier].valid; }

}
