<div class="mat-elevation-z8 col-md-11" style="margin: 0 auto;">
  <div class='row table-header'>
    <mat-form-field class="">
      <input #shareSearch [(ngModel)]="searchField" (keydown.enter)="doFilter(shareSearch.value)" matInput placeholder="Search" autocomplete="off">
      <button (click)="doFilter(shareSearch.value)" mat-button mat-icon-button matSuffix (click)="clearSearchField()" *ngIf="searchField">
        <mat-icon>close</mat-icon>
      </button>
      <mat-icon  matSuffix>search</mat-icon>
      <mat-hint align="start"><strong>Search with material code</strong> </mat-hint>
    </mat-form-field>
  </div>
    <table mat-table class="full-width-table"  [dataSource]="dataSource" matSort>
      <ng-container *ngFor="let disCol of displayedColumns;index as i" matColumnDef="{{disCol}}">
        <th  mat-header-cell *matHeaderCellDef [ngStyle]="{'text-align':'center'}" class="table_header header">{{addSpaces(disCol)}}</th>
      <td mat-cell *matCellDef="let element" class='table_cell' [ngStyle]="{'pointer-events': !update ? 'none' : '','background-color': i==2 ? getBackgroundColor(element['status']) : 'none',color:i==2?getTextColor(element['status']):'','text-align':'center'}" [routerLink]="['/iplanningStock']" [queryParams]="{ Mcode: element['materialCode'],Lcode: element['locationCode'] }"> {{element[disCol]}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator
        [length]="dataSource?.data.length + (this.page ? 1 : 0)"
        [pageIndex]="0"
        [pageSize]="100"
        [pageSizeOptions]="[10, 25, 50, 100]">
    </mat-paginator>
</div>
