<mat-toolbar [ngStyle]="{'background-color': '#FFA100'}">
   <span> Welcome to Flow Smart</span>
   <body style="background-color:black;"></body>
</mat-toolbar>

<app-dashboard-tiles></app-dashboard-tiles>

<!-- <ng-container *ngIf="dashboardReports.length">
   <div class="d-flex w-100">
   <ng-container *ngFor="let report of dashboardReports">
      <app-reports-organism [reportNameAlias]="report"></app-reports-organism>
   </ng-container>
</div>
</ng-container> -->

<!-- <app-reports-organism reportNameAlias="Excess_Inventory"></app-reports-organism> -->


