<div class="container">
    <h1 [ngStyle]="{'color': color,'text-align': 'center','margin-top': '100px'}">{{res}}</h1>
   <div class="center">
    <button
   class="btn btn-primary center
   mat-focus-indicator
   mat-raised-button
   mat-button-base
   mat-primary
   ng-star-inserted"
   [hidden]="!flag"
   (click)="goToMainPage()"
   >
    Continue
   </button>
</div>
</div>

