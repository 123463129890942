import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamicnorm-organism',
  templateUrl: './dynamicnorm-organism.component.html',
  styleUrls: ['./dynamicnorm-organism.component.scss']
})
export class DynamicNormOrganismComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
