<div class="mat-elevation-z8 col-md-11 dflex">
    <div class='table-header'>
        <button class="download-btn" mat-button mat-raised-button color="primary" (click)="downloadTemplate()" >Download Template</button>
    </div>
    <!--Download norms Template added only for stock criteria bulk upload as per req" -->
    <div class='table-header' *ngIf="page === 'stockcriteria'">
        <button class="download-btn" mat-button mat-raised-button color="primary" (click)="downloadFNTemplate()" >Download Forcenorm Template</button>
    </div>
    <div class='table-header'>
        <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="xlsxInputChange($event)" accept=".xlsx"/>
        <button mat-flat-button color="primary" (click)="csvInput.click()">{{uploadName}}</button>
    </div>
</div>

