import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import {api} from '../properties/endpoints';


@Injectable({
  providedIn: 'root'
})
export class MaterialsService {

  constructor(private http: HttpClient) { }

  getAllMaterials() {
    // const api = '../../assets/mock/all_materials.json';
    const url = `${api.host}/material/all`;
    return this.http.get(url);
  }

  getMaterialForm() {
    // const api = '../../assets/mock/allMetaData.json';
    const url = `${api.host}/material/metadata`;
    return this.http.get(url);
  }

  getNextMaterial(page){
    const url = `${api.host}/material/all?${page}`;
      return this.http.get(url); 
   }

  getMaterialByCode(code) {
    // const api = '../../assets/mock/getMaterialByCode.json';
    const url = `${api.host}/material/`+code;
    return this.http.get(url);
  }

  getMaterialAttributes(code) {
    // const apiLocal = '../../assets/mock/getMaterialAttributes.json';
    const url = `${api.host}/material/`+`${code}/attributes`;
    return this.http.get(url);
  }

  getStockForm() {
    const api = '../../assets/mock/getStockMetaData.json';
    return this.http.get(api);
  }

  saveMaterial(formValues, formData, addFlag, id): Observable<any[]>{
      const materialSaveApi = `${api.host}/material/save`;
      const materialAttributeSaveApi = `${api.host}/material/update/attributes`;
      let resultJson = {};
      let resultJsonAttributes = [];
      resultJson['id'] = id;
      formData.map((eachFormData) => {
        if(eachFormData.dynamic) {
          const attributeId = addFlag ? null : eachFormData.id;
          resultJsonAttributes.push({
            'id': attributeId,
            'code': formValues['code'].value,
            'attributeName': eachFormData.identifier,
            'attributeValue': formValues[eachFormData.identifier].value
          });
        } else {
          resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value; 
        }
      });
      return forkJoin([this.http.post<any>(materialSaveApi, resultJson), this.http.post<any>(materialAttributeSaveApi,resultJsonAttributes)]);
  }

}
