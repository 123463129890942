<div class="mat-elevation-z8 col-md-11" style='margin: 0 auto;'>
  <div class='table-header'>
    <mat-form-field fxFlex="40%" style="margin-top:5px;">
      <input matInput type="text" (keyup)="doFilter($event.target.value)" autocomplete="off" placeholder="Search">
    </mat-form-field>
    <button mat-button *ngIf="fuctionality === 'materials'" mat-raised-button color="primary" [routerLink]="['/parameterForm']" [queryParams]="{ fuctionality: 'materials' }">Add</button>
    <button mat-button *ngIf="fuctionality === 'locations'" mat-raised-button color="primary" [routerLink]="['/parameterForm']" [queryParams]="{ fuctionality: 'locations' }">Add</button>
    <button mat-button *ngIf="fuctionality === 'stockcriteria'" mat-raised-button color="primary" [routerLink]="['/parameterForm']" [queryParams]="{ fuctionality: 'stockcriteria' }">Add</button>
    <button mat-button *ngIf="fuctionality === 'shareofbusiness'" mat-raised-button color="primary" [routerLink]="['/parameterForm']" [queryParams]="{ fuctionality: 'shareofbusiness' }">Add</button>
    <!-- <ng-template  #locationBtn>
      <button mat-button mat-raised-button color="primary" [routerLink]="['/parameterForm']" [queryParams]="{ fuctionality: 'locations'}">Add</button>
    </ng-template> -->
  </div>
  <table mat-table class="full-width-table"  [dataSource]="dataSource" matSort>
    <ng-container *ngFor="let disCol of displayedColumns" matColumnDef="{{disCol}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{disCol}} </th>
      <td mat-cell  *matCellDef="let element"> {{element[disCol]}} </td>
      <!-- <div *ngIf="fuctionality === 'materials'; else locationRow">
        <td mat-cell  *matCellDef="let element" [routerLink]="['/parameterForm']" [queryParams]="{ fuctionality: 'materials' }"> {{element[disCol]}} </td>
      </div>
      <ng-template  #locationRow>
        <td mat-cell  *matCellDef="let element" [routerLink]="['/parameterForm']" [queryParams]="{ fuctionality: 'locations' }"> {{element[disCol]}} </td>
      </ng-template> -->
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator #paginator
      [length]="dataSource?.data.length"
      [pageIndex]="0"
      [pageSize]="5"
      [pageSizeOptions]="[10, 25, 50, 100]">
  </mat-paginator>
</div>
