import { FormBase } from '../interfaces/formInterface';

export class RadioField extends FormBase<string> {
  controlType = 'radio';
  options: {key: string, value: string}[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [
      {key: 'true', value: 'True'},
      {key: 'false', value: 'False'}
    ];
  }
}
