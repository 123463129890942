export const ParamsMgtStockCriteria = [
    {
        id: '5f1058132ba72fb0d87ed31e',
        identifier: 'identifier',
        label: 'Identifier',
        dataType: 'String',
        optional: false
    },
    {
        id: '5f1058132ba72fb0d87ed31e',
        identifier: 'label',
        label: 'Label',
        dataType: 'String',
        optional: false
    },
    {
        id: '5f1058132ba72fb0d87ed31e',
        identifier: 'dataType',
        label: 'DataType',
        dataType: 'String',
        optional: false
    },
    {
        id: '5f1058132ba72fb0d87ed31e',
        identifier: 'mandatory',
        label: 'Mandatory',
        dataType: 'String',
        optional: false
    },
    {
        id: '5f1058132ba72fb0d87ed31e',
        identifier: 'Reporting',
        label: 'Reporting',
        dataType: 'Boolean',
        optional: false
    }
];
