
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommonUtilityService } from './common-utility-service';
import { AuthenticationService } from '../services/authentication.service';
@Injectable()

export class AuthGuard implements CanActivate {

    constructor(
        private utilityService: CommonUtilityService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    // canActivate(activatedRoute: ActivatedRouteSnapshot) {
    //     if (this.utilityService.getCookie('isLoggedIn')) {
    //         return true;
    //     }
    //     this.router.navigate(['/dashboard']);
    // }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.utilityService.getCookie('isLoggedIn');
        if (currentUser) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
    }
}
