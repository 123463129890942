<div class="pt-2 pl-2">
  <button mat-button mat-raised-button color="primary" routerLink='/planningStock'>
      <span class="material-icons">
          arrow_back
      </span>
      Back
  </button>
</div>
<app-stock-planning-table [materialCode]="materialCode" [locationCode]="locationCode"></app-stock-planning-table>
<app-bpr-status-history [materialCode]="materialCode" [locationCode]="locationCode"></app-bpr-status-history>
<app-stock-planning-tab [materialCode]="materialCode" [locationCode]="locationCode"></app-stock-planning-tab>
