<mat-card-content>
    <form class="my-form">
       <mat-form-field class="full-width">
              <mat-label>First Name</mat-label>
              <input  matInput  placeholder="First name"  name="fname"  required>
           </mat-form-field>
           <mat-form-field class="full-width">
              <mat-label>Last Name</mat-label>
              <input  matInput  placeholder="Last Name" name="lname"  required>
           </mat-form-field>
           <mat-form-field class="full-width">
              <mat-label>Address</mat-label>
              <input  matInput  placeholder="Address" name="address"  required>
           </mat-form-field>
           <mat-form-field class="full-width">
              <mat-label>Email</mat-label>
              <input  matInput  placeholder="Email" name="email">
           </mat-form-field>
           <mat-form-field class="full-width">
              <mat-label>Password</mat-label>
              <input  matInput  placeholder="Password"  name="password">
           </mat-form-field>
    </form>
  </mat-card-content>