import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-locations-organism',
  templateUrl: './locations-organism.component.html',
  styleUrls: ['./locations-organism.component.scss']
})
export class LocationsOrganismComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
