export const materialColumns = ['code', 'name', 'active'];
export const parameterColumns = ['label', 'dataType', 'optional'];
export const parameterLocationColumns = ['label', 'dataType', 'optional'];
export const parameterStockCriteriaColumns = ['label', 'dataType', 'optional'];
export const parameterShareOfBusinessColumns = ['label', 'dataType', 'optional'];
export const stockCriteriaColumns = ['materialCode', 'locationCode', 'targetSafetyStock', 'packSize'];
export const shareOfBusinessColumns = ['materialCode', 'locationCode', 'supplier', 'percentage'];
export const locationColumns = ['code', 'name','postcode', 'active'];
export const userColumns = ['userId', 'role'];
export const dynamicNormColumns = ['materialCode','locationCode','targetSafetyStock','suggestedNorm'];
export const planningStockColumns = ['materialCode','locationCode','status'];
export const stockTable = ['supplier','quantity','plannedDate'];
// export const innerPlanningStockColumns = ['Material','Name','LocationName','Status'];
// export const stockTable = ['Quantity','Supplier','PlannedDate'];
