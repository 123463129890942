import { Component, OnInit , Input, Output, EventEmitter} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBase } from '../../../interfaces/formInterface';
import { FormControlService } from '../../../services/form-control.service';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-dynamic-form-molecule',
  templateUrl: './dynamic-form-molecule.component.html',
  styleUrls: ['./dynamic-form-molecule.component.scss']
})
export class DynamicFormMoleculeComponent implements OnInit {
  @Input() formData: FormBase<any>[] = [];
  @Input() addFlag: any;
  @Output() submitForm = new EventEmitter<any>();

  constructor(private fcs: FormControlService, private snack: MatSnackBar) { }
  form: FormGroup;
  ngOnInit(): void {
    this.form = this.fcs.toFormGroup(this.formData);
  }
  save() {
    this.submitForm.emit(this.f);
  }

  get f() { return this.form.controls; }

}
