import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulk-upload-organism',
  templateUrl: './bulk-upload-organism.component.html',
  styleUrls: ['./bulk-upload-organism.component.scss']
})
export class BulkUploadOrganismComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
