import { Component, ElementRef, ViewChild,OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { menu } from '../../properties/menu';
import { CommonUtilityService } from '../../services/common-utility-service';
import { Router, ActivatedRouteSnapshot, ChildActivationEnd } from '@angular/router';
import { EmitterService } from '../../services/emitter.service';
import { AuthenticationService } from '../../services/authentication.service';
import { MatSidenav } from '@angular/material/sidenav';
import { UserService } from '../../services/user.service'
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { any } from 'codelyzer/util/function';
@Component({
  selector: 'app-my-nav-template',
  templateUrl: './my-nav-template.component.html',
  styleUrls: ['./my-nav-template.component.scss']
})
export class MyNavTemplateComponent {
  @ViewChild('drawer') public drawer: MatSidenav;
  menu: any;
  currentUser: any;
  isLoggedIn: boolean;
  displayMenu: any;
  mobileView: boolean = false;
  year:any=(new Date()).getFullYear()
  togFunc(parentIndex){
   if(parentIndex===0){
   return true;
   }
   return false;
  }
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  previous: any = menu;
  refreshMenu: boolean = false;
  ts(check) {
    if (check === 'Amazon' || check === 'Flipkart') {
      return true
    }
    return false;
  }
  constructor(private breakpointObserver: BreakpointObserver,
    private utilService: CommonUtilityService,
    private el: ElementRef,
    private router: Router, private emitter: EmitterService,
    private authenticationService: AuthenticationService,
    private UserService: UserService,
    private cdr: ChangeDetectorRef) {
    this.emitter.isLoggedInCheck.subscribe((data) => data ? this.checkAuthenticated() : '');
    this.checkAuthenticated();

    // To Load the menu when refreshSideMenu = true
    // this.emitter.refreshSideMenu.subscribe((res: any) => {
    //   this.menu.splice(0, this.menu.length);
    //   // // this.menu=[]
    //   console.log(this.previous);

    //   this.menu.unshift(this.previous);
    //   console.log(this.menu);

    //   // if(res) {
    //   //   // this.menu=this.previous
    //   //   // this.cdr.detectChanges();
    //   if(res) {
    //     this.refreshMenu = true;
    //     // this.loadMenu();

    //   //   let records = [];
    //   //   this.UserService.getMarketplace().subscribe((data) => {

    //   //     data['marketPlaceAuthDataList'].forEach(element => {

    //   //       element.role = 'Admin'
    //   //       element.entity = 'marketplace'
    //   //       // element.authorised=false
    //   //       records.unshift(element)
    //   //     });

    //   //     let marketPlace = {
    //   //       header: "marketplace",
    //   //       entity: 'marketplace',
    //   //       child: records,
    //   //       icon: 'extension',
    //   //     }
    //   //     console.log(this.menu)
    //   //     this.menu.unshift(marketPlace);
    //   //   })
    //   }

    //   //   console.log(this.menu.length);

    //   // }
    // })
    // console.log(this.previous);
    // this.authenticationService.getRoleDetail().subscribe(data => {
    //   this.role=data.role;
    // })

  }
  logout() {
    this.authenticationService.logout();
    this.checkAuthenticated();
    this.router.navigate(['/login']);
    location.reload();
  }
  login(url) {
    const a = window.open(
      `${url}`, '_blank'
    );
  }
  getName() {
    return this.authenticationService.getUserName();
  }

  checkAuthenticated() {
    this.isLoggedIn = this.authenticationService.isLoggedIn;
    // this.isLoggedIn = this.authenticationService._isLoggedIn ? true : false;
    this.isLoggedIn ? this.loadMenu() : '';
  }
  loadMenu() {
    this.menu = menu;
    this.authenticationService.getRoleDetail().subscribe(data => {
      let dataKey = data && Object.keys(data).reverse();
      data && dataKey.map(i => {
        let dataValue = data[i];
        if (typeof dataValue === 'object' && dataValue !== null) {
          this.reportMenu(i, dataValue, data.role);
        }
      })
      this.accessRulesMapping();
      if(data.role==='Admin'){
        this.UserService.getMarketplace().subscribe((res) => {
          if(res){
          this.marketPlce(res['marketPlaceAuthDataList'])
          }
          this.accessRulesMapping();
        })
      }
    })

  }
  marketPlce(value) {
      let market = value && value.map(i => {
        let submenu = {
          name: i.name,
          role: "Admin",
          entity: 'marketplace',
          data: '',
          key: i.name,
          authorise_url: i.authorise_url,
          authorised: i.authorised
        };
        return submenu;
      })
      let mrkt = {
        header: "Market Place",
        entity: 'marketplace',
        child: market,
        icon: 'extension',
      }
    this.menu.push(mrkt);
  }
  reportMenu(key, value, r) {
    let currentReport = value && value.map(i => {
      let submenu = {
        name: i.replace(/_/g, " "),
        route: '/report',
        role: r,
        entity: 'reports',
        data: '',
        key: i,
      };
      return submenu;
    })
    let report1 = {
      header: key.replace(/([A-Z])/g, ' $1').trim(),
      entity: 'reports',
      child: currentReport,
      icon: 'insert_chart'
    }
    this.menu.unshift(report1);
  }
  accessRulesMapping() {
    const accessRules = this.utilService.getCookie('accessRules') ? JSON.parse(this.utilService.getCookie('accessRules')).accessRules : '';
    const userId = this.authenticationService.getUserId();
    if (accessRules) {
      this.menu.map(m => {
        m.enabled = false;
        m.child.forEach(child => {
          if (child.entity === 'reports') {
            if (child.user && child.user != userId) {
              return;
            }
            accessRules.map(each => {
              if (each.entity === 'reports' || each.entity==='marketplace') {
                if (child.role == each.role) {
                  child.rules = { read: true };
                  m.enabled = true;
                }
              }
            });
          }
          else {
            accessRules.map(each => {
              if (child.entity === each.entity) {
                child.rules = each;
                if (each.create) {
                  m.enabled = true;
                }
              }
            });
          }
        });
      });
    }
  }
  navigate(cat) {
    this.isHandset$.subscribe(data => {
      this.mobileView = data;
    });

    this.mobileView ? this.drawer.toggle() : '';
    if (cat && cat.entity === 'reports') {
      this.router.navigate([cat.route], { queryParams: { 'reportName': cat.key } });
    } else {
      this.router.navigate([cat.route, { data: cat.data ? cat.data : null }]);
    }
  }

}
